import '../css/categories.css';

import CategoryImg1 from '../images/categories/london.webp';
import CategoryImg2 from '../images/categories/haydpark.webp';
import CategoryImg3 from '../images/categories/Marylebone.webp';
import CategoryImg4 from '../images/categories/kensintgon.webp';
import Flat18_14 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 17_7.webp';


import { Link as RouterLink } from 'react-router-dom';

function Categories(){
    return(
        <>
            <section className='categories ar'>
                <div className='categories-content'>
                    


                    <div className='sm-images'>

                        <div>
                            <img src={CategoryImg1} alt="صورة شقة الفئة" />

                            <div className='text'>
                                <h1> 
                                    جميع الشقق
                                </h1>

                                <RouterLink to="/ar/all-apartments"> استكشاف </RouterLink>
                            </div>
                        </div>


                        <div>
                            <img src={CategoryImg2} alt="صورة شقة الفئة" />

                            <div className='text'>
                                <h1> 
                                حديقة هايد
                                </h1>

                                <RouterLink to="/ar/hayd-park-apartments"> استكشاف </RouterLink>

                            </div>
                        </div>


                        <div>
                            <img src={CategoryImg3} alt="صورة شقة الفئة" />

                            <div className='text'>
                                <h1> 
                                ماريليبون
                                </h1>

                                <RouterLink to="/ar/marylebone-apartments"> استكشاف </RouterLink>

                            </div>
                        </div>


                        <div>
                            <img src={Flat18_14} alt="صورة شقة الفئة" />

                            <div className='text'>
                                <h1> 
                                كينسينغتون
                                </h1>

                                <RouterLink to="/ar/kensington-apartments"> استكشاف </RouterLink>

                            </div>
                        </div>


                    </div>


                </div>
            </section>
        </>
    )
}

export default Categories;