import './css/contact.css';

function PartnerWithUs(){
    return(
        <>
            <section className='contact'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-content'>
                                <h2> 
                                Partner with us
                                </h2>

                                <h1>
                                How to partner with us
                                </h1>

                                <p>
                                If you think your property would be a good fit for our portfolio, regardless of your location in Europe, we invite you to get in touch with us. We welcome properties that embody the same level of excellence and dedication to guest satisfaction that defines Zaman Apartments.
                                </p>

                                <ul>
                                    <li>
                                        <a target='_blank' aria-label='google maps link' href="https://goo.gl/maps/fYfb4kP3DCptqyB56">
                                        <i class="las la-map-marked-alt"></i>
                                            8 Cumbrian Gardens London NW21EF
                                        </a>
                                    </li>

                                    <li>
                                        <a target='_blank' aria-label='phonenumber link' href="tel:+447943335010">
                                            <i class="las la-phone-volume"></i>
                                            +44-7943-335010
                                        </a>
                                    </li>


                                    <li>
                                        <a target='_blank' aria-label='email address link' href="mailto:ZamanApartments@gmail.com">
                                            <i class="las la-envelope"></i>
                                            ZamanApartments@gmail.com
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-form'>
                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                                <i class="las la-user"></i>
                                            </label>
                                            <input type='text' placeholder='Name' required/>
                                        </div>

                                        <div className='input'>
                                            <label>
                                                <i class="las la-user"></i>
                                            </label>
                                            <input type='email' placeholder='Email Address' required/>
                                        </div>



                                    </div>



                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                                <i class="las la-phone-volume"></i>
                                            </label>
                                            <input type='tel' placeholder='Phone' required/>
                                        </div>

                                        <div className='input'>
                                            <label>
                                                <i class="las la-info-circle"></i>
                                            </label>
                                            <input type='text' placeholder='Subject' required/>
                                        </div>

                                    </div>


                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                                <i class="las la-pen"></i>
                                            </label>
                                            <textarea required placeholder='How can we help you ?'>

                                            </textarea>
                                        </div>

                                    </div>

                                    <div className='buttons'>
                                        <button type="submit" aria-label='submit-btn'> <i class="las la-paper-plane"></i> Get in Touch </button>

                                        <label>
                                            <input type='checkbox' required />
                                            I agree that my data is collected.
                                        </label>
                                    </div>

                                    
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default PartnerWithUs;