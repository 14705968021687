import '../css/contact.css';

function PartnerWithUs(){
    return(
        <>
            <section className='contact ar'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-content'>
                                <h2> 
                                شارك معنا
                                </h2>

                                <h1>
                                كيفية الشراكة معنا
                                </h1>

                                <p>
                                إذا كنت تعتقد أن عقارك سيكون مناسباً لمحفظتنا، بغض النظر عن موقعك في أوروبا، ندعوك للتواصل معنا. نرحب بالعقارات التي تجسد نفس مستوى التميز والتفاني في رضا الضيوف الذي يحدد شقق زمان.
                                </p>

                                <ul>
                                    <li>
                                        <a target='_blank' aria-label='رابط خرائط جوجل' href="https://goo.gl/maps/fYfb4kP3DCptqyB56">
                                        <i class="las la-map-marked-alt"></i>
                                            8 حدائق كومبريان لندن NW21EF
                                        </a>
                                    </li>

                                    <li>
                                        <a target='_blank' aria-label='رابط رقم الهاتف' href="tel:+447943335010">
                                            <i class="las la-phone-volume"></i>
                                            +44-7943-335010
                                        </a>
                                    </li>


                                    <li>
                                        <a target='_blank' aria-label='رابط عنوان البريد الإلكتروني' href="mailto:ZamanApartments@gmail.com">
                                            <i class="las la-envelope"></i>
                                            ZamanApartments@gmail.com
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='contact-form'>
                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                                <i class="las la-user"></i>
                                            </label>
                                            <input type='text' placeholder='الاسم' required/>
                                        </div>

                                        <div className='input'>
                                            <label>
                                                <i class="las la-user"></i>
                                            </label>
                                            <input type='email' placeholder='عنوان البريد الإلكتروني' required/>
                                        </div>



                                    </div>



                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                                <i class="las la-phone-volume"></i>
                                            </label>
                                            <input type='tel' placeholder='الهاتف' required/>
                                        </div>

                                        <div className='input'>
                                            <label>
                                                <i class="las la-info-circle"></i>
                                            </label>
                                            <input type='text' placeholder='الموضوع' required/>
                                        </div>

                                    </div>


                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                                <i class="las la-pen"></i>
                                            </label>
                                            <textarea required placeholder='كيف يمكننا مساعدتك؟'>

                                            </textarea>
                                        </div>

                                    </div>

                                    <div className='buttons'>
                                        <button type="submit" aria-label='زر الإرسال'> <i class="las la-paper-plane"></i> اتصل بنا </button>

                                        <label>
                                            <input type='checkbox' required />
                                            أوافق على جمع بياناتي.
                                        </label>
                                    </div>

                                    
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default PartnerWithUs;