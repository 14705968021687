import './css/pageheader.css';

import { Link as RouterLink } from 'react-router-dom';


function PageHeader(props){
    return(
        <>
            <section className='page-header'>
                <img src={props.img} />
                <div className='overlay'></div>
                <div className='page-header-text'>
                    <h1> {props.header} </h1>

                    <ul>
                        <li>
                            <RouterLink to="/">
                                Home
                            </RouterLink>
                        </li>

                        <li>
                            <RouterLink to={`/${props.link}`}>
                                {props.linktext}
                            </RouterLink>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}


export default PageHeader;  