import './css/footer.css';
import { Link as RouterLink } from 'react-router-dom';

function Footer(){
    return(
        <>
            <footer>
                <div className='container'>
                    <div className='footer-content'>
                        {/* <h1> Invest in your future. </h1>
                        <h2> Let's talk! </h2> */}

                        <div className='short-links'>

                            <ul>
                            <li>
                                    <RouterLink  to="/">
                                        Home
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink  to="/about-us">
                                        About Us
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink  to="/countries">
                                        Locations
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink  to="/who-we-work-with">
                                        Who We Work With
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink  to="/partner-with-us">
                                        Partner with us
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/contact-us">
                                        Contact Us
                                    </RouterLink>
                                </li>

                            </ul>

                            <div className='socials'>
                                <a target='_blank' href="https://m.facebook.com/profile.php/?id=61556748852430&name=xhp_nt__fb__action__open_user" aria-label='facebook link'>
                                <i class="lab la-facebook-f"></i>

                                </a>

                                <a target='_blank' href="https://www.instagram.com/apar.tments4?igsh=MTZ3azh1NnNlYWF1eQ%3D%3D&utm_source=qr" aria-label='instagram link'>
                                <i class="lab la-instagram"></i>

                                </a>


                                <a target='_blank' href="https://www.linkedin.com/company/zaman-apartments-ltd/" aria-label='linkedin link'>
                                <i class="lab la-linkedin-in"></i>

                                </a>
                            </div>

                        </div>


                        <div className='copy-right'>
                            <h5>ZamanApartments &copy; 2024 . All Rights Reserved</h5>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;