import '../css/landing.css';
import { Link as RouterLink } from 'react-router-dom';

import React, { useState, useEffect } from 'react';


import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';


import {Autoplay, Navigation, Pagination, EffectFade} from 'swiper';

import LandingImg1 from '../images/landingslider/1.webp';
import LandingImg2 from '../images/landingslider/2.webp';
import LandingImg3 from '../images/landingslider/3.webp';
import LandingImg4 from '../images/landingslider/4.webp';





function Landing(){
    return(
        <>
            <section className='landing ar'>

                <div className='landing-back-slider'>
                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} pagination={true}
                        autoplay={{
                            delay: 6000,
                            disableOnInteraction: false,
                        }}
                        effect="fade"
                        modules={[Autoplay, Pagination, EffectFade]}
                        >


                        <SwiperSlide>
                            <div className='landing-img'>
                                <img src={LandingImg1} alt='صورة شقة زمان' />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='landing-img'>
                                <img src={LandingImg2} alt='صورة شقة زمان' />
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='landing-img'>
                                <img src={LandingImg3} alt='صورة شقة زمان' />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='landing-img'>
                                <img src={LandingImg4} alt='صورة شقة زمان' />
                            </div>
                        </SwiperSlide>


                        </Swiper>
                    </div>
                    <div className='overlay'></div>

                    <div className='container'>
                        <div className='landing-text'>
                            <h1>زمان</h1>
                            <h1> الشقق </h1>
                            <div>
                                <i class="las la-arrow-down"></i>
                                <p>
                                في شقق زمان، مهمتنا هي إعادة تعريف الإقامات المؤقتة من خلال توفير مزيج استثنائي من الراحة، الرفاهية، والأناقة لكل ضيف
                                </p>
                                
                            </div>

                            <RouterLink to="/ar/all-apartments"> شققنا </RouterLink>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default Landing;