import '../css/bestdeals.css';
import { Link as RouterLink } from 'react-router-dom';







//marylebone


import Flat6_1 from '../images/flats/area2/1 Bedroom Flat/1.webp';
import Flat6_2 from '../images/flats/area2/1 Bedroom Flat/2.webp';
import Flat6_3 from '../images/flats/area2/1 Bedroom Flat/3.webp';
import Flat6_4 from '../images/flats/area2/1 Bedroom Flat/4.webp';
import Flat6_5 from '../images/flats/area2/1 Bedroom Flat/5.webp';
import Flat6_6 from '../images/flats/area2/1 Bedroom Flat/6.webp';
import Flat6_7 from '../images/flats/area2/1 Bedroom Flat/7.webp';


// import Flat6_8 from '../images/flats/area2/flat1-60/20170706_151850_19.webp';
// import Flat6_9 from '../images/flats/area2/flat1-60/20170706_151918_20.webp';
// import Flat6_10 from '../images/flats/area2/flat1-60/Kitchen 1_2.webp';
// import Flat6_11 from '../images/flats/area2/flat1-60/Living room 1_3.webp';
// import Flat6_12 from '../images/flats/area2/flat1-60/Living room 1_4.webp';
// import Flat6_13 from '../images/flats/area2/flat1-60/Living room 1_5.webp';
// import Flat6_14 from '../images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-1_6.webp';
// import Flat6_15 from '../images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-2_7.webp';
// import Flat6_16 from '../images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-3_8.webp';
// import Flat6_17 from '../images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-4_9.webp';
// import Flat6_18 from '../images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-5_10.webp';
// import Flat6_19 from '../images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-6_11.webp';
// import Flat6_20 from '../images/flats/area2/flat1-60/bathroom 1_21.webp';
// import Flat6_21 from '../images/flats/area2/flat1-60/bedroom 1 1_0.webp';
// import Flat6_22 from '../images/flats/area2/flat1-60/bedroom 1 1_22.webp';
// import Flat6_23 from '../images/flats/area2/flat1-60/bedroom 2 1_1.webp';





import Flat7_1 from '../images/flats/area2/3 Bedroom Duplex Flat/1.webp';
import Flat7_2 from '../images/flats/area2/3 Bedroom Duplex Flat/2.webp';
import Flat7_3 from '../images/flats/area2/3 Bedroom Duplex Flat/3.webp';
import Flat7_4 from '../images/flats/area2/3 Bedroom Duplex Flat/4.webp';
import Flat7_5 from '../images/flats/area2/3 Bedroom Duplex Flat/5.webp';
import Flat7_6 from '../images/flats/area2/3 Bedroom Duplex Flat/6.webp';
import Flat7_7 from '../images/flats/area2/3 Bedroom Duplex Flat/7.webp';
import Flat7_8 from '../images/flats/area2/3 Bedroom Duplex Flat/8.webp';
import Flat7_9 from '../images/flats/area2/3 Bedroom Duplex Flat/9.webp';
import Flat7_10 from '../images/flats/area2/3 Bedroom Duplex Flat/10.webp';

// import Flat7_11 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Dining room 7_47.webp';
// import Flat7_12 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Dining room 9_49.webp';
// import Flat7_13 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Dining room Life Style 2_50.webp';
// import Flat7_14 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Ensuite Bathroom 1_1.webp';
// import Flat7_15 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Ensuite Bathroom 2_2.webp';
// import Flat7_16 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Ensuite Bathroom 3_3.webp';
// import Flat7_17 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Ensuite Bathroom 4_4.webp';
// import Flat7_18 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Hallway 2_5.webp';
// import Flat7_19 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Hallway_6.webp';
// import Flat7_20 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 1_7.webp';
// import Flat7_21 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 3_8.webp';
// import Flat7_22 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 4_9.webp';
// import Flat7_23 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 5_10.webp';
// import Flat7_24 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 6_11.webp';
// import Flat7_25 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen Life Style 1_12.webp';
// import Flat7_26 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen Life Style3_13.webp';
// import Flat7_27 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 1_14.webp';
// import Flat7_28 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 2_15.webp';
// import Flat7_29 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 3_16.webp';
// import Flat7_30 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 4_17.webp';
// import Flat7_31 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 5_18.webp';
// import Flat7_32 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 6_19.webp';
// import Flat7_33 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 7_20.webp';
// import Flat7_34 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 8_21.webp';
// import Flat7_35 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 1_22.webp';
// import Flat7_36 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 2_23.webp';
// import Flat7_37 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 3_24.webp';
// import Flat7_38 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 4_25.webp';
// import Flat7_39 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 5_26.webp';
// import Flat7_40 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 6_27.webp';
// import Flat7_41 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 7_28.webp';
// import Flat7_42 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 8_29.webp';
// import Flat7_43 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 1_30.webp';
// import Flat7_44 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 2_31.webp';
// import Flat7_45 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 3_32.webp';
// import Flat7_46 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 4_33.webp';
// import Flat7_47 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 5_34.webp';
// import Flat7_48 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 6_35.webp';
// import Flat7_49 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 7_36.webp';
// import Flat7_50 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 8_37.webp';
// import Flat7_51 from '../images/flats/area2/flat1-68/Marylebone 1 - 68 Third Bedroom_38.webp';




import Flat8_1 from '../images/flats/area2/2 Bedroom Duplex Flat/1.webp';
import Flat8_2 from '../images/flats/area2/2 Bedroom Duplex Flat/2.webp';
import Flat8_3 from '../images/flats/area2/2 Bedroom Duplex Flat/3.webp';
import Flat8_4 from '../images/flats/area2/2 Bedroom Duplex Flat/4.webp';
import Flat8_5 from '../images/flats/area2/2 Bedroom Duplex Flat/5.webp';
import Flat8_6 from '../images/flats/area2/2 Bedroom Duplex Flat/6.webp';
import Flat8_7 from '../images/flats/area2/2 Bedroom Duplex Flat/7.webp';
import Flat8_8 from '../images/flats/area2/2 Bedroom Duplex Flat/8.webp';
import Flat8_9 from '../images/flats/area2/2 Bedroom Duplex Flat/9.webp';
import Flat8_10 from '../images/flats/area2/2 Bedroom Duplex Flat/10.webp';
import Flat8_11 from '../images/flats/area2/2 Bedroom Duplex Flat/11.webp';
import Flat8_12 from '../images/flats/area2/2 Bedroom Duplex Flat/12.webp';






import Flat9_1 from '../images/flats/area2/2 Bedroom Flat 1/1.webp';
import Flat9_2 from '../images/flats/area2/2 Bedroom Flat 1/2.webp';
import Flat9_3 from '../images/flats/area2/2 Bedroom Flat 1/3.webp';
import Flat9_4 from '../images/flats/area2/2 Bedroom Flat 1/4.webp';
import Flat9_5 from '../images/flats/area2/2 Bedroom Flat 1/5.webp';
import Flat9_6 from '../images/flats/area2/2 Bedroom Flat 1/6.webp';
import Flat9_7 from '../images/flats/area2/2 Bedroom Flat 1/7.webp';
import Flat9_8 from '../images/flats/area2/2 Bedroom Flat 1/8.webp';


// import Flat9_9 from '../images/flats/area2/flat2-60/The Residence-6_6.webp';


import Flat10_1 from '../images/flats/area2/2 Bedroom Flat 2/1.webp';
import Flat10_2 from '../images/flats/area2/2 Bedroom Flat 2/2.webp';
import Flat10_3 from '../images/flats/area2/2 Bedroom Flat 2/3.webp';
import Flat10_4 from '../images/flats/area2/2 Bedroom Flat 2/4.webp';
import Flat10_5 from '../images/flats/area2/2 Bedroom Flat 2/5.webp';
import Flat10_6 from '../images/flats/area2/2 Bedroom Flat 2/6.webp';
import Flat10_7 from '../images/flats/area2/2 Bedroom Flat 2/7.webp';
import Flat10_8 from '../images/flats/area2/2 Bedroom Flat 2/8.webp';




// import Flat11_1 from '../images/flats/area2/flat3-60/Copy of 68 Marylebone Lane 0034_1.webp';
// import Flat11_2 from '../images/flats/area2/flat3-60/Marylebone Lane 60 Flat 3-1 (1)_2.webp';
// import Flat11_3 from '../images/flats/area2/flat3-60/Marylebone Lane 60 Flat 3-2_3.webp';
// import Flat11_4 from '../images/flats/area2/flat3-60/Marylebone Lane 60 Flat 3-3 (1)_4.webp';
// import Flat11_5 from '../images/flats/area2/flat3-60/Marylebone Lane 60 Flat 3-4_0.webp';


// import Flat12_1 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 1_24.webp';
// import Flat12_2 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 2_25.webp';
// import Flat12_3 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 3_26.webp';
// import Flat12_4 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 4_27.webp';
// import Flat12_5 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 5_28.webp';
// import Flat12_6 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 1_29.webp';
// import Flat12_7 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 2_30.webp';
// import Flat12_8 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 3_31.webp';
// import Flat12_9 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 4_32.webp';
// import Flat12_10 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 5_33.webp';
// import Flat12_11 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 10_6.webp';
// import Flat12_12 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 11_7.webp';
// import Flat12_13 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 12_8.webp';
// import Flat12_14 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 13_9.webp';
// import Flat12_15 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 14_10.webp';
// import Flat12_16 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 1_34.webp';
// import Flat12_17 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 2_35.webp';
// import Flat12_18 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 3_36.webp';
// import Flat12_19 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 4_0.webp';
// import Flat12_20 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 5_1.webp';
// import Flat12_21 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 6_2.webp';
// import Flat12_22 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 7_3.webp';
// import Flat12_23 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 8_4.webp';
// import Flat12_24 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 9_5.webp';
// import Flat12_25 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 1_11.webp';
// import Flat12_26 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 2_12.webp';
// import Flat12_27 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 3 (2)_13.webp';
// import Flat12_28 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 3_14.webp';
// import Flat12_29 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 4_15.webp';
// import Flat12_30 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 5_16.webp';
// import Flat12_31 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 6_17.webp';
// import Flat12_32 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 7_18.webp';
// import Flat12_33 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 8_19.webp';
// import Flat12_34 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 9_20.webp';
// import Flat12_35 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom Life Style 2_21.webp';
// import Flat12_36 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom Life Style 3_22.webp';
// import Flat12_37 from '../images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom Life Style_23.webp';





// import Flat13_1 from '../images/flats/area2/flat5-60/60_9.webp';
// import Flat13_2 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-11_8.webp';
// import Flat13_3 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-1_10.webp';
// import Flat13_4 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-2_0.webp';
// import Flat13_5 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-3_1.webp';
// import Flat13_6 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-4_2.webp';
// import Flat13_7 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-5_3.webp';
// import Flat13_8 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-6_4.webp';
// import Flat13_9 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-7_5.webp';
// import Flat13_10 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-8_6.webp';
// import Flat13_11 from '../images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-9_7.webp';





// import Flat14_1 from '../images/flats/area2/flat5-68/68_0.webp';
// import Flat14_2 from '../images/flats/area2/flat5-68/68_1.webp';
// import Flat14_3 from '../images/flats/area2/flat5-68/68_2.webp';
// import Flat14_4 from '../images/flats/area2/flat5-68/68_3.webp';
// import Flat14_5 from '../images/flats/area2/flat5-68/68_4.webp';
// import Flat14_6 from '../images/flats/area2/flat5-68/68_5.webp';
// import Flat14_7 from '../images/flats/area2/flat5-68/68_6.webp';
// import Flat14_8 from '../images/flats/area2/flat5-68/68_7.webp';
// import Flat14_9 from '../images/flats/area2/flat5-68/68_8.webp';


// import Flat15_1 from '../images/flats/area2/flat6-60/60 Marylebone Ln, Marylebone, London W1U 2NZ, UK_26_1.webp';
// import Flat15_2 from '../images/flats/area2/flat6-60/Copy of 60 Marylebone Ln, Marylebone, London W1U 2NZ, UK_8_2.webp';
// import Flat15_3 from '../images/flats/area2/flat6-60/Marylebone Lane 60 Flat 6-8_0.webp';




// import Flat16_1 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (1 of 21)_5.webp';
// import Flat16_2 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (13 of 21)_1.webp';
// import Flat16_3 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (15 of 21)_2.webp';
// import Flat16_4 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (17 of 21)_3.webp';
// import Flat16_5 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (19 of 21)_4.webp';
// import Flat16_6 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (5 of 21)_6.webp';
// import Flat16_7 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (6 of 21)_7.webp';
// import Flat16_8 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (8 of 21)_8.webp';
// import Flat16_9 from '../images/flats/area2/flat7-68/Marylebone Jan 23 Edits (9 of 21)_0.webp';




// import Flat17_1 from '../images/flats/area2/flat8-68/Flat 8 Marylebone Edits (1 of 40)_5.webp';
// import Flat17_2 from '../images/flats/area2/flat8-68/Flat 8 Marylebone Edits (14 of 40)_1.webp';
// import Flat17_3 from '../images/flats/area2/flat8-68/Flat 8 Marylebone Edits (15 of 40)_2.webp';
// import Flat17_4 from '../images/flats/area2/flat8-68/Flat 8 Marylebone Edits (2 of 40)_6.webp';
// import Flat17_5 from '../images/flats/area2/flat8-68/Flat 8 Marylebone Edits (24 of 40)_3.webp';
// import Flat17_6 from '../images/flats/area2/flat8-68/Flat 8 Marylebone Edits (27 of 40)_4.webp';
// import Flat17_7 from '../images/flats/area2/flat8-68/Flat 8 Marylebone Edits (4 of 40)_7.webp';
// import Flat17_8 from '../images/flats/area2/flat8-68/Flat 8 Marylebone Edits (5 of 40)_0.webp';





import Lines from '../images/contact.png';

function MaryLebone(){
    return(
        <>
            <section className='bestdeals ar'>
                <img src={Lines} alt="lines" />
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='bestdeals-header'>
                                <h2>
                                عروض حصرية

                                </h2>

                                <h1> 
                                شقق ماري ليبون

                                </h1>
                            </div>
                        </div>






                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=6">
                                        <img src={Flat6_1} alt="صورة الشقة"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 1</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                </div>

                                    <h1>
                                    شقة بغرفة نوم واحدة
                                    </h1>
                                    <h2>في ماريليبون، W1U</h2>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=7">
                                        <img src={Flat7_1} alt="صورة الشقة"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 3</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 2</h4>

                                </div>

                                    <h1>
                                    شقة دوبلكس بثلاث غرف نوم
                                    </h1>
                                    <h2>في ماريليبون، W1U</h2>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=8">
                                        <img src={Flat8_1} alt="صورة الشقة"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 2</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                </div>

                                    <h1>
                                    شقة دوبلكس بغرفتي نوم
                                    </h1>
                                    <h2>في ماريليبون، W1U</h2>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=9">
                                        <img src={Flat9_1} alt="صورة الشقة"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 2</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                </div>

                                    <h1>
                                    شقة بغرفتي نوم
                                    </h1>
                                    <h2>في ماريليبون، W1U</h2>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=10">
                                        <img src={Flat10_1} alt="صورة الشقة"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 2</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                </div>

                                    <h1>
                                    شقة بغرفتي نوم
                                    </h1>
                                    <h2>في ماريليبون، W1U</h2>

                                </div>
                            </div>
                        </div>

{/* 
                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=11">
                                        <img src={Flat11_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                    شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                    <ul>
                                        <li>
                                            <span> الحالة  </span>
                                            <span> متاح    </span>
                                        </li>



                                        <li>
                                            <span> النوع  </span>
                                            <span> شقة  </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=12">
                                        <img src={Flat12_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                    شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                    <ul>
                                        <li>
                                            <span> الحالة  </span>
                                            <span> متاح    </span>
                                        </li>



                                        <li>
                                            <span> النوع  </span>
                                            <span> شقة  </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=13">
                                        <img src={Flat13_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                    شقة دوبلكس من 3 غرف نوم
                                    </h1>
                                    <h2>عرض</h2>

                                    <ul>
                                        <li>
                                            <span> الحالة  </span>
                                            <span> متاح    </span>
                                        </li>



                                        <li>
                                            <span> النوع  </span>
                                            <span> شقة  </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=14">
                                        <img src={Flat14_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                    شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                    <ul>
                                        <li>
                                            <span> الحالة  </span>
                                            <span> متاح    </span>
                                        </li>



                                        <li>
                                            <span> النوع  </span>
                                            <span> شقة  </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=15">
                                        <img src={Flat15_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                    شقة دوبلكس بغرفتي نوم
                                                                        </h1>
                                    <h2>عرض</h2>

                                    <ul>
                                        <li>
                                            <span> الحالة  </span>
                                            <span> متاح    </span>
                                        </li>



                                        <li>
                                            <span> النوع  </span>
                                            <span> شقة  </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=16">
                                        <img src={Flat16_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                    <ul>
                                        <li>
                                            <span> الحالة  </span>
                                            <span> متاح    </span>
                                        </li>



                                        <li>
                                            <span> النوع  </span>
                                            <span> شقة  </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=17">
                                        <img src={Flat17_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                    <ul>
                                        <li>
                                            <span> الحالة  </span>
                                            <span> متاح    </span>
                                        </li>



                                        <li>
                                            <span> النوع  </span>
                                            <span> شقة  </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
 */}





                    </div>
                </div>
            </section>
        </>
    )
}

export default MaryLebone;