import  '../css/partners.css';
    
import PartnerImg1 from '../images/partners/1.webp';
import PartnerImg2 from '../images/partners/2.webp';
import PartnerImg3 from '../images/partners/3.webp';
import PartnerImg4 from '../images/partners/4.webp';
import PartnerImg5 from '../images/partners/5.webp';
import PartnerImg6 from '../images/partners/6.webp';
import PartnerImg7 from '../images/partners/7.webp';
import PartnerImg8 from '../images/partners/8.webp';
import PartnerImg9 from '../images/partners/9.webp';
import PartnerImg10 from '../images/partners/10.webp';
import PartnerImg11 from '../images/partners/11.webp';
import PartnerImg12 from '../images/partners/12.webp';
import PartnerImg13 from '../images/partners/13.webp';
import PartnerImg14 from '../images/partners/14.webp';
import PageHeader from '../Pageheader';



function Partners(){
    return(
        <>  
            <PageHeader header="من نعمل معهم" linktext="من نعمل معهم" link="who-we-work-with" img={PartnerImg8} />
            <section className='partners ar'>
                <div className='container-fluid'>
                    <div className='row'>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg1} alt="صورة الشريك" />
                                <img src={PartnerImg2} alt="صورة الشريك 2" />
                                <span> 01 </span>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>مسافرو الأعمال</h1>

                                <p>
                                نحن نخدم المحترفين الذين يسافرون لحضور المؤتمرات، الاجتماعات، أو المشاريع قصيرة الأمد، نقدم لهم إقامة مريحة وملائمة في شققنا الفاخرة.
                                </p>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>مسافرو الاستجمام</h1>

                                <p>
                                سواء كانت إجازة نهاية الأسبوع أو عطلة ممتدة، نرحب بالمسافرين للترفيه الذين يسعون لاستكشاف مدينة لندن النابضة بالحياة مع الاستمتاع براحة المنزل.

                                </p>
                            </div>
                        </div>



                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg3} alt="صورة الشريك" />
                                <img src={PartnerImg4} alt="صورة الشريك 2" />
                                <span> 02 </span>
                            </div>
                        </div>




                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg5} alt="صورة الشريك" />
                                <img src={PartnerImg6} alt="صورة الشريك 2" />
                                <span> 03 </span>
                            </div>
                        </div>





                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>زوار الطبية</h1>

                                <p>
                                    نوفر بيئة داعمة وهادئة للأفراد الذين يزورون لندن للعلاج الطبي أو الاستشارات، مضمنين لهم مكانًا هادئًا للاستراحة خلال إقامتهم.
                                </p>
                            </div>
                        </div>






                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>الطلاب والباحثون</h1>

                                <p>
                                توفر شققنا بيئة مناسبة للطلاب والباحثين الذين يسعون لتحقيق أهدافهم الأكاديمية في لندن، مزودين لهم بمساحة مريحة للتركيز والاسترخاء.
                                </p>
                            </div>
                        </div>






                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg7} alt="صورة الشريك" />
                                <img src={PartnerImg8} alt="صورة الشريك 2" />
                                <span> 04 </span>
                            </div>
                        </div>







                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg9} alt="صورة الشريك" />
                                <img src={PartnerImg10} alt="صورة الشريك 2" />
                                <span> 05 </span>
                            </div>
                        </div>



                        
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>المساعدين الشخصيين</h1>

                                <p>
                                نتعاون مع المساعدين الشخصيين لضمان تلبية احتياجات إقامة عملائهم بسلاسة، مما يتيح لهم التركيز على جوانب أخرى من رحلتهم.

                                </p>
                            </div>
                        </div>




                        
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>مديري السفر الشركاتي</h1>

                                <p>
                                نشارك مع مديري السفر الشركاتيين لتوفير حلول إقامة مخصصة لموظفيهم، مما يضمن إقامة مريحة وخالية من المتاعب أثناء رحلات العمل.

                                </p>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg11} alt="صورة الشريك" />
                                <img src={PartnerImg12} alt="صورة الشريك 2" />
                                <span> 06 </span>
                            </div>
                        </div>





                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg13} alt="صورة الشريك" />
                                <img src={PartnerImg14} alt="صورة الشريك 2" />
                                <span> 07 </span>
                            </div>
                        </div>



                    
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>مستشاري الحركة</h1>

                                <p>
                                نعمل عن كثب مع مستشاري الحركة لاستيعاب الأفراد والعائلات الذين ينتقلون إلى لندن مؤقتًا، مما يوفر لهم انتقالًا سلسًا ومكانًا مرحبًا ليسموه موطنًا.

                                </p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}


export default Partners;