import  './css/partners.css';
    
import PartnerImg1 from './images/partners/1.webp';
import PartnerImg2 from './images/partners/2.webp';
import PartnerImg3 from './images/partners/3.webp';
import PartnerImg4 from './images/partners/4.webp';
import PartnerImg5 from './images/partners/5.webp';
import PartnerImg6 from './images/partners/6.webp';
import PartnerImg7 from './images/partners/7.webp';
import PartnerImg8 from './images/partners/8.webp';
import PartnerImg9 from './images/partners/9.webp';
import PartnerImg10 from './images/partners/10.webp';
import PartnerImg11 from './images/partners/11.webp';
import PartnerImg12 from './images/partners/12.webp';
import PartnerImg13 from './images/partners/13.webp';
import PartnerImg14 from './images/partners/14.webp';
import PageHeader from './Pageheader';



function Partners(){
    return(
        <>  
            <PageHeader header="Who We Work With" linktext="Who We Work With" link="who-we-work-with" img={PartnerImg8} />
            <section className='partners'>
                <div className='container-fluid'>
                    <div className='row'>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg1} alt="partner image" />
                                <img src={PartnerImg2} alt="partner image 2" />
                                <span> 01 </span>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>Business Travelers</h1>

                                <p>
                                We cater to professionals traveling for conferences, meetings, or short-term projects, offering them a comfortable and convenient stay in our premium apartments.
                                </p>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>Leisure Travelers</h1>

                                <p>
                                Whether it's a weekend getaway or an extended vacation, we welcome leisure travelers seeking to explore the vibrant city of London while enjoying the comforts of home.

                                </p>
                            </div>
                        </div>



                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg3} alt="partner image" />
                                <img src={PartnerImg4} alt="partner image 2" />
                                <span> 02 </span>
                            </div>
                        </div>




                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg5} alt="partner image" />
                                <img src={PartnerImg6} alt="partner image 2" />
                                <span> 03 </span>
                            </div>
                        </div>





                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>Medical Visitors</h1>

                                <p>
                                    We provide a supportive and tranquil environment for individuals visiting London for medical treatment or consultations, ensuring they have a peaceful retreat during their stay.
                                </p>
                            </div>
                        </div>






                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>Students and Researchers</h1>

                                <p>
                                Our apartments offer a conducive environment for students and researchers pursuing academic endeavors in London, providing them with a comfortable space to focus and relax.
                                </p>
                            </div>
                        </div>






                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg7} alt="partner image" />
                                <img src={PartnerImg8} alt="partner image 2" />
                                <span> 04 </span>
                            </div>
                        </div>







                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg9} alt="partner image" />
                                <img src={PartnerImg10} alt="partner image 2" />
                                <span> 05 </span>
                            </div>
                        </div>



                        
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>Personal Assistants</h1>

                                <p>
                                We collaborate with personal assistants to ensure that their clients' accommodation needs are met seamlessly, allowing them to focus on other aspects of their trip.

                                </p>
                            </div>
                        </div>




                        
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>Corporate Travel Managers</h1>

                                <p>
                                We partner with corporate travel managers to provide tailored accommodation solutions for their employees, ensuring a hassle-free and comfortable stay during business trips.

                                </p>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg11} alt="partner image" />
                                <img src={PartnerImg12} alt="partner image 2" />
                                <span> 06 </span>
                            </div>
                        </div>





                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-img'>
                                <img src={PartnerImg13} alt="partner image" />
                                <img src={PartnerImg14} alt="partner image 2" />
                                <span> 07 </span>
                            </div>
                        </div>



                    
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='partner-text'>
                                <h1>Mobility Consultants</h1>

                                <p>
                                We work closely with mobility consultants to accommodate individuals and families relocating to London temporarily, offering them a smooth transition and a welcoming place to call home.

                                </p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}


export default Partners;