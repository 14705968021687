import '../css/ad.css';
import { Link as RouterLink } from 'react-router-dom';

import VideoLanding from '../videos/landingvid.mp4';

function Ad(){
    return(
        <>
            <section className='ad ar'>
                <video src={VideoLanding} alt="إعلان فيديو" autoPlay muted loop></video>
                <div className='overlay'></div>
                
                <div className='container'>
                    <div className='ad-content'>
                        <h3> 
                            استكشف عقاراتنا
                        </h3>

                        <h1> 
                            اكتشف الوسائل والميزات التي تجعلنا فريدين
                        </h1>

                        <RouterLink to="/ar">
                            اعرف أكثر
                        </RouterLink>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ad;