import '../css/bestdeals.css';
import { Link as RouterLink } from 'react-router-dom';




// kensington






import Flat18_1 from '../images/flats/area3/1 BEDROOM FLAT/1.webp';
import Flat18_2 from '../images/flats/area3/1 BEDROOM FLAT/2.webp';
import Flat18_3 from '../images/flats/area3/1 BEDROOM FLAT/3.webp';
import Flat18_4 from '../images/flats/area3/1 BEDROOM FLAT/4.webp';
import Flat18_5 from '../images/flats/area3/1 BEDROOM FLAT/5.webp';
import Flat18_6 from '../images/flats/area3/1 BEDROOM FLAT/6.webp';
import Flat18_7 from '../images/flats/area3/1 BEDROOM FLAT/7.webp';
import Flat18_8 from '../images/flats/area3/1 BEDROOM FLAT/8.webp';
import Flat18_9 from '../images/flats/area3/1 BEDROOM FLAT/9.webp';


// import Flat18_10 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 1_10.webp';
// import Flat18_11 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 3(1)_11.webp';
// import Flat18_12 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 3_12.webp';
// import Flat18_13 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 4(1)_13.webp';
// import Flat18_14 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 4_14.webp';
// import Flat18_15 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 5(1)_15.webp';
// import Flat18_16 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 5_16.webp';
// import Flat18_17 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 6(1)_17.webp';
// import Flat18_18 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 6_18.webp';
// import Flat18_19 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 8(1)_19.webp';
// import Flat18_20 from '../images/flats/area3/flat101/RUSSELL GARDENS, 101 - 8_0.webp';





import Flat19_1 from '../images/flats/area3/2 Bedroom Flat with Terrace/1.webp';
import Flat19_2 from '../images/flats/area3/2 Bedroom Flat with Terrace/2.webp';
import Flat19_3 from '../images/flats/area3/2 Bedroom Flat with Terrace/3.webp';
import Flat19_4 from '../images/flats/area3/2 Bedroom Flat with Terrace/4.webp';
import Flat19_5 from '../images/flats/area3/2 Bedroom Flat with Terrace/5.webp';
import Flat19_6 from '../images/flats/area3/2 Bedroom Flat with Terrace/6.webp';
import Flat19_7 from '../images/flats/area3/2 Bedroom Flat with Terrace/7.webp';
import Flat19_8 from '../images/flats/area3/2 Bedroom Flat with Terrace/8.webp';


// import Flat19_9 from '../images/flats/area3/flat102/RUSSELL GARDENS, 102 - 4_10.webp';
// import Flat19_10 from '../images/flats/area3/flat102/RUSSELL GARDENS, 102 - 5_11.webp';
// import Flat19_11 from '../images/flats/area3/flat102/RUSSELL GARDENS, 102 - 7_0.webp';
// import Flat19_12 from '../images/flats/area3/flat102/RUSSELL GARDENS, 102 - 8_1.webp';





// import Flat20_1 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 10_2.webp';
// import Flat20_2 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 11_3.webp';
// import Flat20_3 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 13_4.webp';
// import Flat20_4 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 15_5.webp';
// import Flat20_5 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 17_6.webp';
// import Flat20_6 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 18_7.webp';
// import Flat20_7 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 1_8.webp';
// import Flat20_8 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 2_9.webp';
// import Flat20_9 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 4_10.webp';
// import Flat20_10 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 5_11.webp';
// import Flat20_11 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 6_0.webp';
// import Flat20_12 from '../images/flats/area3/flat104/RUSSELL GARDENS, 104 - 8_1.webp';




// import Flat21_1 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 12_4.webp';
// import Flat21_2 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 13_5.webp';
// import Flat21_3 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 14_6.webp';
// import Flat21_4 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 16_7.webp';
// import Flat21_5 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 18_8.webp';
// import Flat21_6 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 1_9.webp';
// import Flat21_7 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 2_10.webp';
// import Flat21_8 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 4_11.webp';
// import Flat21_9 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 5_0.webp';
// import Flat21_10 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 6_1.webp';
// import Flat21_11 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 8_2.webp';
// import Flat21_12 from '../images/flats/area3/flat201/RUSSELL GARDENS, 201 - 9_3.webp';



// import Flat22_1 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 11_4.webp';
// import Flat22_2 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 12_5.webp';
// import Flat22_3 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 14_6.webp';
// import Flat22_4 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 16_7.webp';
// import Flat22_5 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 18_8.webp';
// import Flat22_6 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 1_9.webp';
// import Flat22_7 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 3_10.webp';
// import Flat22_8 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 4_11.webp';
// import Flat22_9 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 5_0.webp';
// import Flat22_10 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 6_1.webp';
// import Flat22_11 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 8_2.webp';
// import Flat22_12 from '../images/flats/area3/flat202/RUSSELL GARDENS, 202 - 9_3.webp';





// import Flat23_1 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-10_2.webp';
// import Flat23_2 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-12_3.webp';
// import Flat23_3 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-13_4.webp';
// import Flat23_4 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-15_5.webp';
// import Flat23_5 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-17_6.webp';
// import Flat23_6 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-1_7.webp';
// import Flat23_7 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-2_8.webp';
// import Flat23_8 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-4_9.webp';
// import Flat23_9 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-5_10.webp';
// import Flat23_10 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-6_11.webp';
// import Flat23_11 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-8_0.webp';
// import Flat23_12 from '../images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-9_1.webp';





// import Flat24_1 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-10_4.webp';
// import Flat24_2 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-12_5.webp';
// import Flat24_3 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-13_6.webp';
// import Flat24_4 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-15_7.webp';
// import Flat24_5 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-17_8.webp';
// import Flat24_6 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-19_9.webp';
// import Flat24_7 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-1_10.webp';
// import Flat24_8 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-2_11.webp';
// import Flat24_9 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-4_0.webp';
// import Flat24_10 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-5_1.webp';
// import Flat24_11 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-6_2.webp';
// import Flat24_12 from '../images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-8_3.webp';





// import Flat25_1 from '../images/flats/area3/flat301/Kensington 301 - Bathroom 1_25.webp';
// import Flat25_2 from '../images/flats/area3/flat301/Kensington 301 - Bathroom 2_26.webp';
// import Flat25_3 from '../images/flats/area3/flat301/Kensington 301 - Bathroom 4_27.webp';
// import Flat25_4 from '../images/flats/area3/flat301/Kensington 301 - Bathroom 5 (2)_28.webp';
// import Flat25_5 from '../images/flats/area3/flat301/Kensington 301 - Bathroom 6_29.webp';
// import Flat25_6 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 10_3.webp';
// import Flat25_7 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 11_4.webp';
// import Flat25_8 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 1_30.webp';
// import Flat25_9 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 2_31.webp';
// import Flat25_10 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 3_32.webp';
// import Flat25_11 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 4_33.webp';
// import Flat25_12 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 5_34.webp';
// import Flat25_13 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 6_35.webp';
// import Flat25_14 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 7_0.webp';
// import Flat25_15 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 8_1.webp';
// import Flat25_16 from '../images/flats/area3/flat301/Kensington 301 - Bedroom 9_2.webp';
// import Flat25_17 from '../images/flats/area3/flat301/Kensington 301 - Kitchen and Dining_5.webp';
// import Flat25_18 from '../images/flats/area3/flat301/Kensington 301 - Kitchen_6.webp';
// import Flat25_19 from '../images/flats/area3/flat301/Kensington 301 - Living Room 10_14.webp';
// import Flat25_20 from '../images/flats/area3/flat301/Kensington 301 - Living Room 11_15.webp';
// import Flat25_21 from '../images/flats/area3/flat301/Kensington 301 - Living Room 3_7.webp';
// import Flat25_22 from '../images/flats/area3/flat301/Kensington 301 - Living Room 4_8.webp';
// import Flat25_23 from '../images/flats/area3/flat301/Kensington 301 - Living Room 5_9.webp';
// import Flat25_24 from '../images/flats/area3/flat301/Kensington 301 - Living Room 6_10.webp';
// import Flat25_25 from '../images/flats/area3/flat301/Kensington 301 - Living Room 7_11.webp';
// import Flat25_26 from '../images/flats/area3/flat301/Kensington 301 - Living Room 8 (2)_12.webp';
// import Flat25_27 from '../images/flats/area3/flat301/Kensington 301 - Living Room 9_13.webp';
// import Flat25_28 from '../images/flats/area3/flat301/Kensington 301 - Living Room_16.webp';
// import Flat25_29 from '../images/flats/area3/flat301/Kensington 301- Bedroom 6_17.webp';
// import Flat25_30 from '../images/flats/area3/flat301/Kensington 301- Living Room 12_18.webp';
// import Flat25_31 from '../images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-13_23.webp';
// import Flat25_32 from '../images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-18_24.webp';
// import Flat25_33 from '../images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-2_19.webp';
// import Flat25_34 from '../images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-5_20.webp';
// import Flat25_35 from '../images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-6_21.webp';
// import Flat25_36 from '../images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-9_22.webp';







// import Flat26_1 from '../images/flats/area3/flat302/Kensington 302 - Bathroom _1.webp';
// import Flat26_2 from '../images/flats/area3/flat302/Kensington 302 - Bedroom_2.webp';
// import Flat26_3 from '../images/flats/area3/flat302/Kensington 302 - Kitchen and Living 4_0.webp';




// import Flat27_1 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-1(1)_11.webp';
// import Flat27_2 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-10_3.webp';
// import Flat27_3 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-12_4.webp';
// import Flat27_4 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-15_5.webp';
// import Flat27_5 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-16_6.webp';
// import Flat27_6 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-17(1)_7.webp';
// import Flat27_7 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-17_8.webp';
// import Flat27_8 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-18(1)_9.webp';
// import Flat27_9 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-18_10.webp';
// import Flat27_10 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-1_12.webp';
// import Flat27_11 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-2_13.webp';
// import Flat27_12 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-4(1)_14.webp';
// import Flat27_13 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-4_15.webp';
// import Flat27_14 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-5_16.webp';
// import Flat27_15 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-6(1)_0.webp';
// import Flat27_16 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-6_1.webp';
// import Flat27_17 from '../images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-8_2.webp';





import Lines from '../images/contact.png';

function Kensington(){
    return(
        <>
            <section className='bestdeals ar'>
                <img src={Lines} alt="lines" />
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='bestdeals-header'>
                                <h2>
                                عروض حصرية

                                </h2>

                                <h1> 
                                شقق كنسينغتون

                                </h1>
                            </div>
                        </div>


                 

                    
                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=18">
                                        <img src={Flat18_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <div>

                                        <h4> <span> <i class="las la-bed"></i></span> 1</h4>
                                        <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                    </div>



                                    <h1>
                                    شقة بغرفة نوم واحدة  
                                    </h1>
                                    <h2>في KENSINGTON, W14</h2>

                                
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=19">
                                        <img src={Flat19_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

                                    <div>

                                    <h4> <span> <i class="las la-bed"></i></span> 2</h4>
                                    <h4> <span> <i class="las la-bath"></i></span> 2</h4>

                                    </div>


                                    <h1>
                                    شقة بغرفتي نوم مع تراس
                                    </h1>
                                    <h2>في  KENSINGTON, W1T</h2>

                                
                                </div>
                            </div>
                        </div>




{/* 
                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=20">
                                        <img src={Flat20_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=21">
                                        <img src={Flat21_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=22">
                                        <img src={Flat22_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=23">
                                        <img src={Flat23_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=24">
                                        <img src={Flat24_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=25">
                                        <img src={Flat25_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=26">
                                        <img src={Flat26_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=27">
                                        <img src={Flat27_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div> */}




                    </div>
                </div>
            </section>
        </>
    )
}

export default Kensington;