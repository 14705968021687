import './css/landing.css';
import { Link as RouterLink } from 'react-router-dom';

import React, { useState,useEffect } from 'react';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';


import {Autoplay,Navigation,Pagination,EffectFade} from 'swiper';

import LandingImg1 from './images/landingslider/1.webp';
import LandingImg2 from './images/landingslider/2.webp';
import LandingImg3 from './images/landingslider/3.webp';
import LandingImg4 from './images/landingslider/4.webp';





function Landing(){
    return(
        <>
            <section className='landing'>

                <div className='landing-back-slider'>
                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={'auto'} pagination={true}
                        autoplay={{
                            delay: 6000,
                            disableOnInteraction: false,
                        }}
                        effect="fade"
                        modules={[Autoplay,Pagination,EffectFade]}
                        >


                        <SwiperSlide>
                            <div className='landing-img'>
                                <img src={LandingImg1} alt='zaman apartment image' />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='landing-img'>
                                <img src={LandingImg2} alt='zaman apartment image' />
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='landing-img'>
                                <img src={LandingImg3} alt='zaman apartment image' />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='landing-img'>
                                <img src={LandingImg4} alt='zaman apartment image' />
                            </div>
                        </SwiperSlide>


                        </Swiper>
                    </div>
                    <div className='overlay'></div>

                    <div className='container'>
                        <div className='landing-text'>
                            <h1>ZAMAN</h1>
                            <h1> APARTMENTS </h1>
                            <div>
                                <i class="las la-arrow-down"></i>
                                <p>
                                At Zaman Apartments, our mission is to redefine temporary accommodations by providing an exceptional blend of comfort, convenience, and style for every guest
                                </p>
                                
                            </div>

                            <RouterLink to="/all-apartments"> Our Apartments </RouterLink>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default Landing;