import './css/aboutus.css';
import { Link as RouterLink } from 'react-router-dom';
import ImgPageHeader  from './images/flats/area1/LORes32/33 60PL Living Area - 3_16.webp';

import LinedBulinding from './images/buildinglined.png';

import AboutImgLg from './images/aboutsection/aboutlg_0.webp';
import AboutImgSm from './images/aboutsection/aboutsm_1.webp';
import PageHeader from './Pageheader';



function AboutUs(){
    return(
        <>
            <PageHeader header="ABOUT US" linktext="About Us" link="about-us" img={ImgPageHeader}/>
            <section className='about-us'>
                <img src={LinedBulinding} alt='building lined draw' />
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-images'>
                                {/* <div>
                                    <h1> 2022 </h1>
                                    <h2> LAUNCH IN</h2>
                                </div> */}

                                <img className='lg-img' src={AboutImgLg} alt='apartment image' />
                                <img className='sm-img' src={AboutImgSm} alt='apartment image' />

                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-text'>
                                <h3>
                                Home-like Amenities
                                </h3>

                                <h1> 
                                    Enjoy the convenience of fully equipped Apartments
                                </h1>

                                <p>
                                    Welcome to  Zaman Apartments with our curated selection of premium short-stay apartments, your stay in the heart of London becomes an unforgettable experience. Whether you're travelling for business, leisure, medical reasons, or study, our mission is to provide you with a tranquil haven that combines the warmth of home with the amenities of a luxury apartment.
                                </p>
{/* 
                                <h4>
                                MON-FRI: 9 AM – 22 PM
                                </h4>

                                <h4>
                                SATURDAY: 9 AM – 20 PM


                                </h4> */}

                                <a aria-label='contact us page link' href="https://wa.me/+447943335010">
                                    Contact Us
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs;