import './css/rates.css';

import React, { useState,useEffect } from 'react';


import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';


import Person1 from './users/img1_3.webp';
import Person2 from './users/img2_0.webp';
import Person3 from './users/img3_1.webp';



function Rates(){
    return(
        <>
            <section className='rates'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='rate-people'>
                                <div className='image'>
                                    <img src={Person1} alt="person image" />
                                    <span> 
                                        <i class="las la-quote-left"></i>
                                    </span>
                                </div>

                                <div className='image'>
                                    <img src={Person2} alt="person image" />
                                    <span> 
                                        <i class="las la-quote-left"></i>
                                    </span>
                                </div>

                                <div className='image'>
                                    <img src={Person3} alt="person image" />
                                    <span> 
                                        <i class="las la-quote-left"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='rate-text'>
                            <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                                nextEl: '.next-btn',
                                prevEl: '.prev-btn',
                                }} pagination={false}
                                    autoplay={{
                                        delay: 6000,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Autoplay,Navigation,Pagination]}
                                    >

                                        <SwiperSlide>
                                        <div className='text'>
                                            <p>

                                                As a customer who recently stayed at Zaman Apartments, I'm thrilled to share my experience. From the moment I booked my short-stay apartment, it was clear that Zaman Apartments prioritizes customer satisfaction and comfort
                                                </p>

                                                <p>
                                                The apartment was situated in the heart of London, providing easy access to the city's vibrant life, yet it offered a peaceful retreat from the hustle and bustle.
                                                </p>
                                            </div>
                                        </SwiperSlide>


                                        <SwiperSlide>
                                        <div className='text'>
                                                <p>

                                                The apartment itself was a marvel - beautifully furnished, with a keen eye for detail that made the space not just a place to stay, but a place to live, even if temporarily. The amenities provided were top-notch, blending the warmth of home with the luxury one would expect from a high-end apartment
                                                </p>

                                                <p>
                                                It was evident that Zaman Apartments had thought of everything, from the high-speed internet for business needs to the comfortable living spaces perfect for relaxation.
                                                </p>
                                            </div>
                                        </SwiperSlide>


                                        <SwiperSlide>
                                        <div className='text'>
                                            <p>
                                                The customer service was exceptional, with the staff going above and beyond to ensure my stay was nothing short of perfect
                                            </p>

                                            <p>
                                                Their dedication to creating a tranquil haven for all types of travelers, whether there for business, leisure, medical reasons, or study, was evident throughout my stay.
                                            </p>
                                            </div>
                                        </SwiperSlide>


                                    </Swiper>

                                
                                
                            </div>

                            <div className='buttons'>
                                <button className='prev-btn' aria-label='left slide button'><i class="las la-arrow-left"></i></button>
                                <button className='next-btn' aria-label='next slide button'><i class="las la-arrow-right"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Rates;