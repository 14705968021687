import '../css/aboutus.css';
import { Link as RouterLink } from 'react-router-dom';
import ImgPageHeader from '../images/flats/area1/LORes32/33 60PL Living Area - 3_16.webp';

import LinedBulinding from '../images/buildinglined.png';

import AboutImgLg from '../images/aboutsection/aboutlg_0.webp';
import AboutImgSm from '../images/aboutsection/aboutsm_1.webp';
import PageHeader from '../Pageheader';

function AboutUs() {
    return (
        <>
            <PageHeader header="عنا" linktext="عنا" link="about-us" img={ImgPageHeader}/>
            <section className='about-us ar'>
                <img src={LinedBulinding} alt='رسم مبنى متقطع' />
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-images'>
                                {/* <div>
                                    <h1> 2022 </h1>
                                    <h2> إطلاق في</h2>
                                </div> */}

                                <img className='lg-img' src={AboutImgLg} alt='صورة شقة' />
                                <img className='sm-img' src={AboutImgSm} alt='صورة شقة' />

                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='about-text'>
                                <h3>
                                وسائل الراحة المنزلية
                                </h3>

                                <h1>
                                    استمتع براحة الشقق المجهزة بالكامل
                                </h1>

                                <p>
                                    مرحباً بكم في شقق زمان، مع مجموعتنا المختارة من الشقق قصيرة الإقامة عالية الجودة، تصبح إقامتك في قلب لندن تجربة لا تنسى. سواء كنت تسافر للعمل، الاستجمام، لأسباب طبية، أو للدراسة، مهمتنا هي توفير لك ملاذاً هادئاً يجمع بين دفء المنزل ووسائل الراحة في شقة فاخرة.
                                </p>
{/* 
                                <h4>
                                الإثنين-الجمعة: 9 صباحاً – 22 مساءً
                                </h4>

                                <h4>
                                السبت: 9 صباحاً – 20 مساءً


                                </h4> */}

                                <a aria-label='رابط صفحة اتصل بنا' href="https://wa.me/+447943335010">
                                    اتصل بنا
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs;