import './css/categories.css';

import CategoryImg1 from './images/categories/london.webp';
import CategoryImg2 from './images/countries/1.webp';
import CategoryImg3 from './images/countries/2.webp';
import CategoryImg4 from './images/countries/3.webp';


import { Link as RouterLink } from 'react-router-dom';

function Countries(){
    return(
        <>
            <section className='categories'>
                <div className='categories-content'>
                    


                    <div className='sm-images'>

                        <div>
                            <img src={CategoryImg1} alt="category apartment image" />

                            <div className='text'>
                                <h1> 
                                    LONDON
                                </h1>

                                <RouterLink to="/london-categories"> Explore </RouterLink>
                            </div>
                        </div>


                        <div>
                            <img src={CategoryImg2} alt="category apartment image" />

                            <div className='text'>
                                <h1> 
                                    FRANCE
                                </h1>

                                <RouterLink to=""> COMING SOON </RouterLink>

                            </div>
                        </div>


                        {/* <div>
                            <img src={CategoryImg3} alt="category apartment image" />

                            <div className='text'>
                                <h1> 
                                GERMANY
                                </h1>

                                <RouterLink to=""> COMING SOON </RouterLink>

                            </div>
                        </div> */}


                        <div>
                            <img src={CategoryImg4} alt="category apartment image" />

                            <div className='text'>
                                <h1> 
                                    ALGERIA
                                </h1>

                                <RouterLink to=""> COMING SOON </RouterLink>

                            </div>
                        </div>


                    </div>


                </div>
            </section>
        </>
    )
}

export default Countries;