import './css/ad.css';
import { Link as RouterLink } from 'react-router-dom';

import VideoLanding from './videos/landingvid.mp4';

function Ad(){
    return(
        <>
            <section className='ad'>
            <video src={VideoLanding} alt="video ad" autoPlay muted loop playsInline></video>
                <div className='overlay'></div>
                
                <div className='container'>
                    <div className='ad-content'>
                        <h3> 
                            Explore Our Property
                        </h3>

                        <h1> 
                            Uncover the Amenities and Features That Make Us Unique
                        </h1>

                        <RouterLink to="/">
                            Learn More
                        </RouterLink>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ad;