import '../css/map.css';
import Logo from '../images/logo.svg';

function Map(){
    return(
        <>
            <section className='map ar'>
                <div className='map-content'>
                    <div className='logo'>
                        <img src={Logo} alt="logo zaman" />
                    </div>

                    <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d158858.1825451866!2d-0.1015987!3d51.5286416!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2seg!4v1707932492350!5m2!1sen!2seg" width="100%" height="500" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </>
    )
}

export default Map;