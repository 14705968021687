import '../css/services.css';

function Services(){
    return(
        <>
            <section className='our-services ar'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='services-header'>
                                <div className='left'>
                                    <h2>
                                    ما نقدمه
                                    </h2>
                                    <h1> 
                                    خدمات تعزز حياتك
                                    </h1>
                                </div>

                                <div className='right'>
                                    <p>
                                    تقع شقق زمان في قلب لندن النابض بالحياة، وهي أكثر من مجرد مكان للراحة – إنها بيتك بعيداً عن الوطن
                                    </p>

                                    <p>
                                    تعرض مجموعتنا المنتقاة بعناية من الشقق الاندماج المثالي بين الراحة التي تبحث عنها في منزل والمرافق الفاخرة المرتبطة بفندق عالي الجودة
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                    <i class="las la-parking"></i>

                                <h3>
                                    مرافق للمواقف
                                </h3>

                                <a href="#">
                                    <span>اعرف المزيد</span>
                                    <span><i class="las la-arrow-right"></i></span>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <i class="las la-swimming-pool"></i>

                                <h3>

                                مسابح للسكان
                                </h3>

                                <a href="#">
                                    <span>اعرف المزيد</span>
                                    <span><i class="las la-arrow-right"></i></span>
                                </a>
                            </div>
                        </div>



                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <i class="las la-object-group"></i>

                                <h3>

                                التخطيط الفردي
                                </h3>

                                <a href="#">
                                    <span>اعرف المزيد</span>
                                    <span><i class="las la-arrow-right"></i></span>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <i class="las la-paint-roller"></i>

                                <h3>

                                التشطيب الداخلي
                                </h3>

                                <a href="#">
                                    <span>اعرف المزيد</span>
                                    <span><i class="las la-arrow-right"></i></span>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </> 
    )
}

export default Services;