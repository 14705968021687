import './css/services.css';

function Services(){
    return(
        <>
            <section className='our-services'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='services-header'>
                                <div className='left'>
                                    <h2>
                                    WHAT WE OFFER
                                    </h2>
                                    <h1> 
                                    Services that enhance your life
                                    </h1>
                                </div>

                                <div className='right'>
                                    <p>
                                    Nestled in the vibrant heart of london, zaman apartments is more than just a place to rest your head – it's a home away from home
                                    </p>

                                    <p>
                                    Our thoughtfully curated selection of apartments showcases the perfect fusion of the coziness you seek in a home and the luxury amenities associated with a high-end hotel
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                    <i class="las la-parking"></i>

                                <h3>
                                    Parking facilities
                                </h3>

                                <a href="#">
                                    <span>Know More</span>
                                    <span><i class="las la-arrow-right"></i></span>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <i class="las la-swimming-pool"></i>

                                <h3>

                                Pools for residents
                                </h3>

                                <a href="#">
                                    <span>Know More</span>
                                    <span><i class="las la-arrow-right"></i></span>
                                </a>
                            </div>
                        </div>



                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <i class="las la-object-group"></i>

                                <h3>

                                Individual planning
                                </h3>

                                <a href="#">
                                    <span>Know More</span>
                                    <span><i class="las la-arrow-right"></i></span>
                                </a>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='service'>
                                <i class="las la-paint-roller"></i>

                                <h3>

                                Interior finishing
                                </h3>

                                <a href="#">
                                    <span>Know More</span>
                                    <span><i class="las la-arrow-right"></i></span>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </> 
    )
}

export default Services;