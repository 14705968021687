import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import Navbar from './components/Navbar';
import Landing from './components/Landing';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Ad from './components/Ad';
import BestDeals from './components/BestDeals';
import Rates from './components/Rates';
import Categories from './components/Categories';
import Contact from './components/Contact';
import Map from './components/Map';
import Footer from './components/Footer';
import PageHeader from './components/Pageheader';


//page headers images

import PageHeaderLocationsImg from './components/images/pageheaders/locations.webp';
import PageHeaderPartnerWithUsImg from './components/images/partnerwithus/partnerw.webp';
import PageHeaderContactWithUsImg from './components/images/contactus/contactus.webp';

import Partners from './components/Partners';
import PartnerWithUs from './components/PartnerWithUs';
import AllApartments from './components/AllApartmenrs';
import HaydPark from './components/HaydPark';
import MaryLebone from './components/MaryLebone';
import Kensington from './components/Kensington';
import Apartment from './components/Apartment';
import Countries from './components/Countries';





import NavbarAR from './components/ar/Navbar';
import LandingAR from './components/ar/Landing';
import AboutUsAR from './components/ar/AboutUs';
import ServicesAR from './components/ar/Services';
import AdAR from './components/ar/Ad';
import BestDealsAR from './components/ar/BestDeals';
import RatesAR from './components/ar/Rates';
import CategoriesAR from './components/ar/Categories';
import ContactAR from './components/ar/Contact';
import MapAR from './components/ar/Map';
import FooterAR from './components/ar/Footer';
import PageHeaderAR from './components/ar/Pageheader';
import PartnersAR from './components/ar/Partners';
import PartnerWithUsAR from './components/ar/PartnerWithUs';
import AllApartmentsAR from './components/ar/AllApartmenrs';
import HaydParkAR from './components/ar/HaydPark';
import MaryLeboneAR from './components/ar/MaryLebone';
import KensingtonAR from './components/ar/Kensington';
import ApartmentAR from './components/ar/Apartment';
import CountriesAR from './components/ar/Countries';

function App() {

return (
    <div className="App">

            <Routes>
            
            <Route path="/" element={
                    <>
                        <Navbar />
                        <Landing />
                        <Services />
                        <Ad />
                        <Countries />
                        <Contact />
                        <Footer />

                    </>
                } />


            <Route path="/about-us" element={
                    <>
                        <Navbar />
                        <AboutUs />

                        <Footer />

                    </>
                } />    

                
            <Route path="/countries" element={
                    <>
                        <Navbar />
                        <PageHeader header="Countries" link="countries" linktext="Countries" img={PageHeaderLocationsImg} />
                        <Countries />
                        <Footer />

                    </>
                } />  


            <Route path="/who-we-work-with" element={
                    <>
                        <Navbar />
                        <Partners />
                        <Footer />

                    </>
                } />  

                <Route path="/partner-with-us" element={
                    <>
                        <Navbar />
                        <PageHeader header="Partner With Us" link="partner-with-us" linktext="Partner With Us" img={PageHeaderPartnerWithUsImg} />
                        <PartnerWithUs />
                        <Map />

                        <Footer />

                    </>
                } />


                <Route path="/contact-us" element={
                    <>
                        <Navbar />
                        <PageHeader header="Contact Us" link="contact-us" linktext="Contact Us" img={PageHeaderContactWithUsImg} />
                        <Contact />
                        <Map />
                        <Footer />

                    </>
                } />


            <Route path="/all-apartments" element={
                    <>
                        <Navbar />
                        <AllApartments />
                        <Footer />

                    </>
                } />

                <Route path="/hyde-park-apartments" element={
                    <>
                        <Navbar />
                        <HaydPark />
                        <Footer />

                    </>
                } />


                <Route path="/marylebone-apartments" element={
                    <>
                        <Navbar />
                        <MaryLebone />
                        <Footer />

                    </>
                } />


            <Route path="/kensington-apartments" element={
                    <>
                        <Navbar />
                        <Kensington />
                        <Footer />

                    </>
                } />



                <Route path="/apartment" element={
                    <>
                        <Navbar />
                        <Apartment />
                        <Footer />

                    </>
                } />


                
            <Route path="/london-categories" element={
                    <>
                        <Navbar />
                        <PageHeader header="Locations" link="locations" linktext="Locations" img={PageHeaderLocationsImg} />
                        <Categories />
                        <Footer />

                    </>
                } />








<Route path="/ar" element={
                    <>
                        <NavbarAR />
                        <LandingAR />
                        <ServicesAR />
                        <AdAR />
                        <CountriesAR />
                        <ContactAR />
                        <FooterAR />

                    </>
                } />


            <Route path="/ar/about-us" element={
                    <>
                        <NavbarAR />
                        <AboutUsAR />

                        <FooterAR />

                    </>
                } />    

                
            <Route path="/ar/countries" element={
                    <>
                        <NavbarAR />
                        <PageHeaderAR header="الدول" link="countries" linktext="الدول" img={PageHeaderLocationsImg} />
                        <CountriesAR />
                        <FooterAR />

                    </>
                } />  


            <Route path="/ar/who-we-work-with" element={
                    <>
                        <NavbarAR />
                        <PartnersAR />
                        <FooterAR />

                    </>
                } />  

                <Route path="/ar/partner-with-us" element={
                    <>
                        <NavbarAR />
                        <PageHeaderAR header="شارك معنا" link="partner-with-us" linktext="شارك معنا" img={PageHeaderPartnerWithUsImg} />
                        <PartnerWithUsAR />
                        <MapAR />

                        <FooterAR />

                    </>
                } />


                <Route path="/ar/contact-us" element={
                    <>
                        <NavbarAR />
                        <PageHeaderAR header="اتصل بنا" link="contact-us" linktext="اتصل بنا" img={PageHeaderContactWithUsImg} />
                        <ContactAR />
                        <MapAR />
                        <FooterAR />

                    </>
                } />


            <Route path="/ar/all-apartments" element={
                    <>
                        <NavbarAR />
                        <AllApartmentsAR />
                        <FooterAR />

                    </>
                } />

                <Route path="/ar/hayd-park-apartments" element={
                    <>
                        <NavbarAR />
                        <HaydParkAR />
                        <FooterAR />

                    </>
                } />


                <Route path="/ar/marylebone-apartments" element={
                    <>
                        <NavbarAR />
                        <MaryLeboneAR />
                        <FooterAR />

                    </>
                } />


            <Route path="/ar/kensington-apartments" element={
                    <>
                        <NavbarAR />
                        <KensingtonAR />
                        <FooterAR />

                    </>
                } />



                <Route path="/ar/apartment" element={
                    <>
                        <NavbarAR />
                        <ApartmentAR />
                        <FooterAR />

                    </>
                } />


                
            <Route path="/ar/london-categories" element={
                    <>
                        <NavbarAR />
                        <PageHeaderAR header="المواقع" link="locations" linktext="المواقع" img={PageHeaderLocationsImg} />
                        <CategoriesAR />
                        <FooterAR />

                    </>
                } />



        </Routes>



        
    </div>
);
}

export default App;
