import '../css/navbar.css';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

import $ from 'jquery';

import Logo from '../images/logo.svg';



import arFlag from '../images/icons/ar.png';
import enFlag from '../images/icons/en.jpg';


function Navbar(){


    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-eles").slideDown(400);
            $("nav").addClass("active");

            $(".nav-eles").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-eles").slideUp(400);
            $("nav").removeClass("active");

        });

        if ($(window).width() < 950) {
            $(".nav-eles ul li").on("click", function() {
                $(".nav-eles").slideUp(400);
                $("nav").removeClass("active");

            });
        }
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);
    



    const [isActive, setIsActive] = useState(false);
    const sideContactInfoRef = useRef(null);

    // Open side contact info
    const openContactInfo = () => setIsActive(true);

    // Close side contact info
    const closeContactInfo = () => setIsActive(false);

    // Click outside to close side contact info
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sideContactInfoRef.current && !sideContactInfoRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [sideContactInfoRef]);



    const location = useLocation(); // Use the useLocation hook to access the current location


    // Click outside to close side contact info
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sideContactInfoRef.current && !sideContactInfoRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [sideContactInfoRef]);

    // Function to determine if the link is active based on the current path
    const getNavLinkClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };


    return(
        <>
            <nav className='ar'>
                <div className="container">
                    <div className="nav-content">
                        <div className="logo">
                            <img  src={Logo} alt="شعار زمان"/>
                        </div>

                                
                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-eles">
                        <button id="close-nav"> <i className="las la-times"></i> </button>

                            <ul>
                            <li>
                                    <RouterLink className={getNavLinkClass('/')} to="/ar/">
                                        الرئيسية
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink className={getNavLinkClass('/about-us')} to="/ar/about-us">
                                        من نحن
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink className={getNavLinkClass('/countries')} to="/ar/countries">
                                        المواقع
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink className={getNavLinkClass('/who-we-work-with')} to="/ar/who-we-work-with">
                                        مع من نعمل
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink className={getNavLinkClass('/partner-with-us')} to="/ar/partner-with-us">
                                        شركاء معنا
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink className={getNavLinkClass('/contact-us')} to="/ar/contact-us">
                                        اتصل بنا
                                    </RouterLink>
                                </li>

                                <li>
                                <RouterLink className={getNavLinkClass('/ar/')} to="/ar/">
                                        <img  src={arFlag} alt="saudi arabia flag"/>
                                    </RouterLink>
                                    <RouterLink className={getNavLinkClass('/')} to="/">
                                        <img  src={enFlag} alt="English flag"/>
                                    </RouterLink>
                                </li>
                            </ul>
                        </div>

                        <div className='contact-info'>
                            <a aria-label='رابط واتساب' href="https://wa.me/+447943335010">
                                <i class="las la-sms"></i>
                            </a>

                            <button id="open-contact-info" onClick={openContactInfo}> 
                                <i className="las la-braille"></i>
                            </button>


                            <div className={`side-contact-info ${isActive ? 'active' : ''}`} ref={sideContactInfoRef}>
                                <div className='side-content'>
                                    <div>
                                        <img  src={Logo} alt="شعار زمان"/>
                                        <button id="close-contact-info" onClick={closeContactInfo}> <i className="las la-times"></i> </button>
                                    </div>

                                    <ul>
                                        <li>
                                            <RouterLink className={getNavLinkClass('/about-us')} to="/ar/about-us">
                                                من نحن
                                            </RouterLink>
                                        </li>

                                        <li>
                                            <RouterLink className={getNavLinkClass('/countries')} to="/ar/countries">
                                                المواقع
                                            </RouterLink>
                                        </li>




                                        <li>
                                        <RouterLink className={getNavLinkClass('/ar/')} to="/ar/">
                                                <img  src={arFlag} alt="saudi arabia flag"/>
                                            </RouterLink>
                                            <RouterLink className={getNavLinkClass('/')} to="/">
                                                <img  src={enFlag} alt="English flag"/>
                                            </RouterLink>
                                        </li>
                                        
                                        </ul>

                                        <a aria-label='رقم الهاتف للاتصال' href='tel:+447943335010'>
                                            + 447 943 335 010
                                        </a>

                                        <a aria-label='البريد الإلكتروني' href='mailto:ZamanApartments@gmail.com'>
                                            ZamanApartments@gmail.com
                                        </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;