import './css/categories.css';

import CategoryImg1 from './images/categories/london.webp';
import CategoryImg2 from './images/categories/haydpark.webp';
import CategoryImg3 from './images/categories/Marylebone.webp';
import CategoryImg4 from './images/categories/kensintgon.webp';


import Flat18_14 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 17_7.webp';


import { Link as RouterLink } from 'react-router-dom';

function Categories(){
    return(
        <>
            <section className='categories'>
                <div className='categories-content'>
                    


                    <div className='sm-images'>

                        <div>
                            <img src={CategoryImg1} alt="category apartment image" />

                            <div className='text'>
                                <h1> 
                                    All Apartments
                                </h1>

                                <RouterLink to="/all-apartments"> Explore </RouterLink>
                            </div>
                        </div>


                        <div>
                            <img src={CategoryImg2} alt="category apartment image" />

                            <div className='text'>
                                <h1> 
                                Hyde Park 
                                </h1>

                                <RouterLink to="/hyde-park-apartments"> Explore </RouterLink>

                            </div>
                        </div>


                        <div>
                            <img src={CategoryImg3} alt="category apartment image" />

                            <div className='text'>
                                <h1> 
                                Marylebone
                                </h1>

                                <RouterLink to="/marylebone-apartments"> Explore </RouterLink>

                            </div>
                        </div>


                        <div>
                            <img src={Flat18_14} alt="category apartment image" />

                            <div className='text'>
                                <h1> 
                                Kensington
                                </h1>

                                <RouterLink to="/kensington-apartments"> Explore </RouterLink>

                            </div>
                        </div>


                    </div>


                </div>
            </section>
        </>
    )
}

export default Categories;