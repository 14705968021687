import '../css/bestdeals.css';
import { Link as RouterLink } from 'react-router-dom';

// haydpark


import Flat1_1 from '../images/flats/area1/LORes117/117 55PL - Living Room - 6_5.webp';
import Flat1_2 from '../images/flats/area1/LORes117/117 55PL - Living Room - 7_0.webp';
import Flat1_3 from '../images/flats/area1/LORes117/117 55PL Kitchen - 2_1.webp';
import Flat1_4 from '../images/flats/area1/LORes117/117 55PL Living Room - 2_2.webp';
import Flat1_5 from '../images/flats/area1/LORes117/117 55PL Living Room - 3_3.webp';
import Flat1_6 from '../images/flats/area1/LORes117/117 55PL Living Room - 7_4.webp';


import Flat2_1 from '../images/flats/area1/LORes118/118 55PL Bathroom One - 1_18.webp';
import Flat2_2 from '../images/flats/area1/LORes118/118 55PL Bathroom Two - 1_0.webp';
import Flat2_3 from '../images/flats/area1/LORes118/118 55PL Bedroom One - 1_1.webp';
import Flat2_4 from '../images/flats/area1/LORes118/118 55PL Bedroom One - 2_2.webp';
import Flat2_5 from '../images/flats/area1/LORes118/118 55PL Bedroom One - 3_3.webp';
import Flat2_6 from '../images/flats/area1/LORes118/118 55PL Bedroom One - 4_4.webp';
import Flat2_7 from '../images/flats/area1/LORes118/118 55PL Bedroom Two - 1_5.webp';
import Flat2_8 from '../images/flats/area1/LORes118/118 55PL Bedroom Two - 2_6.webp';
import Flat2_9 from '../images/flats/area1/LORes118/118 55PL Bedroom Two - 3_7.webp';
import Flat2_10 from '../images/flats/area1/LORes118/118 55PL Bedroom Two - 4_8.webp';
import Flat2_11 from '../images/flats/area1/LORes118/118 55PL Bedroom Two - 5_9.webp';
import Flat2_12 from '../images/flats/area1/LORes118/118 55PL Kitchen - 1_10.webp';
import Flat2_13 from '../images/flats/area1/LORes118/118 55PL Kitchen - 2_11.webp';
import Flat2_14 from '../images/flats/area1/LORes118/118 55PL Living Room - 1_12.webp';
import Flat2_15 from '../images/flats/area1/LORes118/118 55PL Living Room - 2_13.webp';
import Flat2_16 from '../images/flats/area1/LORes118/118 55PL Living Room - 5_14.webp';
import Flat2_17 from '../images/flats/area1/LORes118/118 55PL Living Room - 6_15.webp';
import Flat2_18 from '../images/flats/area1/LORes118/118 55PL Living Room - 7_16.webp';
import Flat2_19 from '../images/flats/area1/LORes118/118 55PL View - 1_17.webp';


import Flat3_1 from '../images/flats/area1/LORes178/17 855PL Bedroom - 6_0.webp';
import Flat3_2 from '../images/flats/area1/LORes178/178 55PL - Bedroom - 4_1.webp';
import Flat3_3 from '../images/flats/area1/LORes178//178 55PL Bathroom - 1_2.webp';
import Flat3_4 from '../images/flats/area1/LORes178/178 55PL Bedroom - 1_3.webp';
import Flat3_5 from '../images/flats/area1/LORes178/178 55PL Bedroom - 2_4.webp';
import Flat3_6 from '../images/flats/area1/LORes178/178 55PL Bedroom - 3_5.webp';
import Flat3_7 from '../images/flats/area1/LORes178/178 55PL Bedroom - 5_6.webp';
import Flat3_8 from '../images/flats/area1/LORes178/178 55PL Flexible Room - 1_7.webp';
import Flat3_9 from '../images/flats/area1/LORes178/178 55PL Hallway - 1_8.webp';
import Flat3_10 from '../images/flats/area1/LORes178/178 55PL Kitchen - 1_9.webp';
import Flat3_11 from '../images/flats/area1/LORes178/178 55PL Kitchen - 2_10.webp';
import Flat3_12 from '../images/flats/area1/LORes178/178 55PL Kitchen - 3_11.webp';
import Flat3_13 from '../images/flats/area1/LORes178/178 55PL Kitchen - 4_12.webp';
import Flat3_14 from '../images/flats/area1/LORes178/178 55PL Kitchen - 5_13.webp';
import Flat3_15 from '../images/flats/area1/LORes178/178 55PL Living Room - 1_14.webp';
import Flat3_16 from '../images/flats/area1/LORes178/178 55PL Living Room - 2_15.webp';
import Flat3_17 from '../images/flats/area1/LORes178/178 55PL Living Room - 3_16.webp';
import Flat3_18 from '../images/flats/area1/LORes178/178 55PL Living Room - 4_17.webp';
import Flat3_19 from '../images/flats/area1/LORes178/178 55PL Living Room - 5_18.webp';
import Flat3_20 from '../images/flats/area1/LORes178/178 55PL Living Room - 6_19.webp';
import Flat3_21 from '../images/flats/area1/LORes178/178 55PL Utility - 1_20.webp';
import Flat3_22 from '../images/flats/area1/LORes178/Mayfair Shot_21.webp';





import Flat4_1 from '../images/flats/area1/LORes32/33 60PL Bathroom One - 1_0.webp';
import Flat4_2 from '../images/flats/area1/LORes32/33 60PL Bathroom One - 2_1.webp';
import Flat4_3 from '../images/flats/area1/LORes32/33 60PL Bathroom One - 3_2.webp';
import Flat4_4 from '../images/flats/area1/LORes32/33 60PL Bathroom One - 3_2.webp';
import Flat4_5 from '../images/flats/area1/LORes32/33 60PL Bathroom Two - 1_3.webp';
import Flat4_6 from '../images/flats/area1/LORes32/33 60PL Bedroom - 1_4.webp';
import Flat4_7 from '../images/flats/area1/LORes32/33 60PL Bedroom - 2_5.webp';
import Flat4_8 from '../images/flats/area1/LORes32/33 60PL Bedroom - 3_6.webp';
import Flat4_9 from '../images/flats/area1/LORes32/33 60PL Bedroom - 4_7.webp';
import Flat4_10 from '../images/flats/area1/LORes32/33 60PL Bedroom - 5_8.webp';
import Flat4_11 from '../images/flats/area1/LORes32/33 60PL Bedroom - 6_9.webp';
import Flat4_12 from '../images/flats/area1/LORes32/33 60PL Flexible Room - 1_10.webp';
import Flat4_13 from '../images/flats/area1/LORes32/33 60PL Flexible Room - 2_11.webp';
import Flat4_14 from '../images/flats/area1/LORes32/33 60PL Kitchen - 1_12.webp';
import Flat4_15 from '../images/flats/area1/LORes32/33 60PL Kitchen - 2_13.webp';
import Flat4_16 from '../images/flats/area1/LORes32/33 60PL Living Area - 1_14.webp';
import Flat4_17 from '../images/flats/area1/LORes32/33 60PL Living Area - 2_15.webp';
import Flat4_18 from '../images/flats/area1/LORes32/33 60PL Living Area - 3_16.webp';
import Flat4_19 from '../images/flats/area1/LORes32/33 60PL Living Area - 4_17.webp';
import Flat4_20 from '../images/flats/area1/LORes32/33 60PL Living Area - 5_18.webp';
import Flat4_21 from '../images/flats/area1/LORes32/33 60PL Living Area - 6_19.webp';





import Flat5_1 from '../images/flats/area1/LORes33/33 60PL Bathroom One - 1_0.webp';
import Flat5_2 from '../images/flats/area1/LORes33/33 60PL Bathroom One - 2_1.webp';
import Flat5_3 from '../images/flats/area1/LORes33/33 60PL Bathroom One - 3_2.webp';
import Flat5_4 from '../images/flats/area1/LORes33/33 60PL Bathroom Two - 1_3.webp';
import Flat5_5 from '../images/flats/area1/LORes33/33 60PL Bedroom - 1_4.webp';
import Flat5_6 from '../images/flats/area1/LORes33/33 60PL Bedroom - 2_5.webp';
import Flat5_7 from '../images/flats/area1/LORes33/33 60PL Bedroom - 3_6.webp';
import Flat5_8 from '../images/flats/area1/LORes33/33 60PL Bedroom - 4_7.webp';
import Flat5_9 from '../images/flats/area1/LORes33/33 60PL Bedroom - 5_8.webp';
import Flat5_10 from '../images/flats/area1/LORes33/33 60PL Bedroom - 6_9.webp';
import Flat5_11 from '../images/flats/area1/LORes33/33 60PL Flexible Room - 1_10.webp';
import Flat5_12 from '../images/flats/area1/LORes33/33 60PL Flexible Room - 2_11.webp';
import Flat5_13 from '../images/flats/area1/LORes33/33 60PL Kitchen - 1_12.webp';
import Flat5_14 from '../images/flats/area1/LORes33/33 60PL Kitchen - 2_13.webp';
import Flat5_15 from '../images/flats/area1/LORes33/33 60PL Living Area - 1_14.webp';
import Flat5_16 from '../images/flats/area1/LORes33/33 60PL Living Area - 2_15.webp';
import Flat5_17 from '../images/flats/area1/LORes33/33 60PL Living Area - 3_16.webp';
import Flat5_18 from '../images/flats/area1/LORes33/33 60PL Living Area - 4_17.webp';
import Flat5_19 from '../images/flats/area1/LORes33/33 60PL Living Area - 5_18.webp';
import Flat5_20 from '../images/flats/area1/LORes33/33 60PL Living Area - 6_19.webp';





import Lines from '../images/contact.png';

function HaydPark(){
    return(
        <>
            <section className='bestdeals ar'>
                <img src={Lines} alt="lines" />
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='bestdeals-header'>
                                <h2>
                                عروض حصرية

                                </h2>

                                <h1> 
                                شقق هايد بارك

                                </h1>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=1">
                                        <img src={Flat1_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>


<div className='text'>
<div>

                                        <h4> <span> <i class="las la-bed"></i></span> </h4>
                                        <h4> <span> <i class="las la-bath"></i></span> </h4>

                                    </div>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>



                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=2">
                                        <img src={Flat2_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>


<div className='text'>
<div>

                                        <h4> <span> <i class="las la-bed"></i></span> </h4>
                                        <h4> <span> <i class="las la-bath"></i></span> </h4>

                                    </div>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=3">
                                        <img src={Flat3_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>


                                    <div className='text'>
                                    <div>

                                        <h4> <span> <i class="las la-bed"></i></span> </h4>
                                        <h4> <span> <i class="las la-bath"></i></span> </h4>

                                    </div>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>


{/* 


                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=4">
                                        <img src={Flat4_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>


<div className='text'>
<div>

                                        <h4> <span> <i class="las la-bed"></i></span> </h4>
                                        <h4> <span> <i class="las la-bath"></i></span> </h4>

                                    </div>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/ar/apartment?id=5">
                                        <img src={Flat5_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>


<div className='text'>
<div>

                                        <h4> <span> <i class="las la-bed"></i></span> </h4>
                                        <h4> <span> <i class="las la-bath"></i></span> </h4>

                                    </div>
                                    <h1>
                                        شقة 
                                    </h1>
                                    <h2>عرض</h2>

                                  
                                </div>
                            </div>
                        </div>



 */}




                    </div>
                </div>
            </section>
        </>
    )
}

export default HaydPark;