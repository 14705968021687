import '../css/categories.css';

import CategoryImg1 from '../images/categories/london.webp';
import CategoryImg2 from '../images/countries/1.webp';
import CategoryImg3 from '../images/countries/2.webp';
import CategoryImg4 from '../images/countries/3.webp';


import { Link as RouterLink } from 'react-router-dom';

function Countries(){
    return(
        <>
            <section className='categories ar'>
                <div className='categories-content'>



                    <div className='sm-images'>

                        <div>
                            <img src={CategoryImg1} alt="صورة شقة الفئة" />

                            <div className='text'>
                                <h1> 
                                    لندن
                                </h1>

                                <RouterLink to="/ar/london-categories"> استكشاف </RouterLink>
                            </div>
                        </div>


                        <div>
                            <img src={CategoryImg2} alt="صورة شقة الفئة" />

                            <div className='text'>
                                <h1> 
                                    فرنسا
                                </h1>

                                <RouterLink to=""> قريبًا </RouterLink>

                            </div>
                        </div>


                        {/* <div>
                            <img src={CategoryImg3} alt="صورة شقة الفئة" />

                            <div className='text'>
                                <h1> 
                                ألمانيا
                                </h1>

                                <RouterLink to=""> قريبًا </RouterLink>

                            </div>
                        </div> */}


                        <div>
                            <img src={CategoryImg4} alt="صورة شقة الفئة" />

                            <div className='text'>
                                <h1> 
                                    الجزائر
                                </h1>

                                <RouterLink to=""> قريبًا </RouterLink>

                            </div>
                        </div>


                    </div>


                </div>
            </section>
        </>
    )
}

export default Countries;