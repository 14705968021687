import './css/bestdeals.css';
import { Link as RouterLink } from 'react-router-dom';

// haydpark



import Flat1_1 from './images/flats/area1/LORes117/117 55PL - Living Room - 6_5.webp';
import Flat1_2 from './images/flats/area1/LORes117/117 55PL - Living Room - 7_0.webp';
import Flat1_3 from './images/flats/area1/LORes117/117 55PL Kitchen - 2_1.webp';
import Flat1_4 from './images/flats/area1/LORes117/117 55PL Living Room - 2_2.webp';
import Flat1_5 from './images/flats/area1/LORes117/117 55PL Living Room - 3_3.webp';
import Flat1_6 from './images/flats/area1/LORes117/117 55PL Living Room - 7_4.webp';


import Flat2_1 from './images/flats/area1/LORes118/118 55PL Bathroom One - 1_18.webp';
import Flat2_2 from './images/flats/area1/LORes118/118 55PL Bathroom Two - 1_0.webp';
import Flat2_3 from './images/flats/area1/LORes118/118 55PL Bedroom One - 1_1.webp';
import Flat2_4 from './images/flats/area1/LORes118/118 55PL Bedroom One - 2_2.webp';
import Flat2_5 from './images/flats/area1/LORes118/118 55PL Bedroom One - 3_3.webp';
import Flat2_6 from './images/flats/area1/LORes118/118 55PL Bedroom One - 4_4.webp';
import Flat2_7 from './images/flats/area1/LORes118/118 55PL Bedroom Two - 1_5.webp';
import Flat2_8 from './images/flats/area1/LORes118/118 55PL Bedroom Two - 2_6.webp';
import Flat2_9 from './images/flats/area1/LORes118/118 55PL Bedroom Two - 3_7.webp';
import Flat2_10 from './images/flats/area1/LORes118/118 55PL Bedroom Two - 4_8.webp';
import Flat2_11 from './images/flats/area1/LORes118/118 55PL Bedroom Two - 5_9.webp';
import Flat2_12 from './images/flats/area1/LORes118/118 55PL Kitchen - 1_10.webp';
import Flat2_13 from './images/flats/area1/LORes118/118 55PL Kitchen - 2_11.webp';
import Flat2_14 from './images/flats/area1/LORes118/118 55PL Living Room - 1_12.webp';
import Flat2_15 from './images/flats/area1/LORes118/118 55PL Living Room - 2_13.webp';
import Flat2_16 from './images/flats/area1/LORes118/118 55PL Living Room - 5_14.webp';
import Flat2_17 from './images/flats/area1/LORes118/118 55PL Living Room - 6_15.webp';
import Flat2_18 from './images/flats/area1/LORes118/118 55PL Living Room - 7_16.webp';
import Flat2_19 from './images/flats/area1/LORes118/118 55PL View - 1_17.webp';


import Flat3_1 from './images/flats/area1/LORes178/17 855PL Bedroom - 6_0.webp';
import Flat3_2 from './images/flats/area1/LORes178/178 55PL - Bedroom - 4_1.webp';
import Flat3_3 from './images/flats/area1/LORes178//178 55PL Bathroom - 1_2.webp';
import Flat3_4 from './images/flats/area1/LORes178/178 55PL Bedroom - 1_3.webp';
import Flat3_5 from './images/flats/area1/LORes178/178 55PL Bedroom - 2_4.webp';
import Flat3_6 from './images/flats/area1/LORes178/178 55PL Bedroom - 3_5.webp';
import Flat3_7 from './images/flats/area1/LORes178/178 55PL Bedroom - 5_6.webp';
import Flat3_8 from './images/flats/area1/LORes178/178 55PL Flexible Room - 1_7.webp';
import Flat3_9 from './images/flats/area1/LORes178/178 55PL Hallway - 1_8.webp';
import Flat3_10 from './images/flats/area1/LORes178/178 55PL Kitchen - 1_9.webp';
import Flat3_11 from './images/flats/area1/LORes178/178 55PL Kitchen - 2_10.webp';
import Flat3_12 from './images/flats/area1/LORes178/178 55PL Kitchen - 3_11.webp';
import Flat3_13 from './images/flats/area1/LORes178/178 55PL Kitchen - 4_12.webp';
import Flat3_14 from './images/flats/area1/LORes178/178 55PL Kitchen - 5_13.webp';
import Flat3_15 from './images/flats/area1/LORes178/178 55PL Living Room - 1_14.webp';
import Flat3_16 from './images/flats/area1/LORes178/178 55PL Living Room - 2_15.webp';
import Flat3_17 from './images/flats/area1/LORes178/178 55PL Living Room - 3_16.webp';
import Flat3_18 from './images/flats/area1/LORes178/178 55PL Living Room - 4_17.webp';
import Flat3_19 from './images/flats/area1/LORes178/178 55PL Living Room - 5_18.webp';
import Flat3_20 from './images/flats/area1/LORes178/178 55PL Living Room - 6_19.webp';
import Flat3_21 from './images/flats/area1/LORes178/178 55PL Utility - 1_20.webp';
import Flat3_22 from './images/flats/area1/LORes178/Mayfair Shot_21.webp';





import Flat4_1 from './images/flats/area1/LORes32/33 60PL Bathroom One - 1_0.webp';
import Flat4_2 from './images/flats/area1/LORes32/33 60PL Bathroom One - 2_1.webp';
import Flat4_3 from './images/flats/area1/LORes32/33 60PL Bathroom One - 3_2.webp';
import Flat4_4 from './images/flats/area1/LORes32/33 60PL Bathroom One - 3_2.webp';
import Flat4_5 from './images/flats/area1/LORes32/33 60PL Bathroom Two - 1_3.webp';
import Flat4_6 from './images/flats/area1/LORes32/33 60PL Bedroom - 1_4.webp';
import Flat4_7 from './images/flats/area1/LORes32/33 60PL Bedroom - 2_5.webp';
import Flat4_8 from './images/flats/area1/LORes32/33 60PL Bedroom - 3_6.webp';
import Flat4_9 from './images/flats/area1/LORes32/33 60PL Bedroom - 4_7.webp';
import Flat4_10 from './images/flats/area1/LORes32/33 60PL Bedroom - 5_8.webp';
import Flat4_11 from './images/flats/area1/LORes32/33 60PL Bedroom - 6_9.webp';
import Flat4_12 from './images/flats/area1/LORes32/33 60PL Flexible Room - 1_10.webp';
import Flat4_13 from './images/flats/area1/LORes32/33 60PL Flexible Room - 2_11.webp';
import Flat4_14 from './images/flats/area1/LORes32/33 60PL Kitchen - 1_12.webp';
import Flat4_15 from './images/flats/area1/LORes32/33 60PL Kitchen - 2_13.webp';
import Flat4_16 from './images/flats/area1/LORes32/33 60PL Living Area - 1_14.webp';
import Flat4_17 from './images/flats/area1/LORes32/33 60PL Living Area - 2_15.webp';
import Flat4_18 from './images/flats/area1/LORes32/33 60PL Living Area - 3_16.webp';
import Flat4_19 from './images/flats/area1/LORes32/33 60PL Living Area - 4_17.webp';
import Flat4_20 from './images/flats/area1/LORes32/33 60PL Living Area - 5_18.webp';
import Flat4_21 from './images/flats/area1/LORes32/33 60PL Living Area - 6_19.webp';





import Flat5_1 from './images/flats/area1/LORes33/33 60PL Bathroom One - 1_0.webp';
import Flat5_2 from './images/flats/area1/LORes33/33 60PL Bathroom One - 2_1.webp';
import Flat5_3 from './images/flats/area1/LORes33/33 60PL Bathroom One - 3_2.webp';
import Flat5_4 from './images/flats/area1/LORes33/33 60PL Bathroom Two - 1_3.webp';
import Flat5_5 from './images/flats/area1/LORes33/33 60PL Bedroom - 1_4.webp';
import Flat5_6 from './images/flats/area1/LORes33/33 60PL Bedroom - 2_5.webp';
import Flat5_7 from './images/flats/area1/LORes33/33 60PL Bedroom - 3_6.webp';
import Flat5_8 from './images/flats/area1/LORes33/33 60PL Bedroom - 4_7.webp';
import Flat5_9 from './images/flats/area1/LORes33/33 60PL Bedroom - 5_8.webp';
import Flat5_10 from './images/flats/area1/LORes33/33 60PL Bedroom - 6_9.webp';
import Flat5_11 from './images/flats/area1/LORes33/33 60PL Flexible Room - 1_10.webp';
import Flat5_12 from './images/flats/area1/LORes33/33 60PL Flexible Room - 2_11.webp';
import Flat5_13 from './images/flats/area1/LORes33/33 60PL Kitchen - 1_12.webp';
import Flat5_14 from './images/flats/area1/LORes33/33 60PL Kitchen - 2_13.webp';
import Flat5_15 from './images/flats/area1/LORes33/33 60PL Living Area - 1_14.webp';
import Flat5_16 from './images/flats/area1/LORes33/33 60PL Living Area - 2_15.webp';
import Flat5_17 from './images/flats/area1/LORes33/33 60PL Living Area - 3_16.webp';
import Flat5_18 from './images/flats/area1/LORes33/33 60PL Living Area - 4_17.webp';
import Flat5_19 from './images/flats/area1/LORes33/33 60PL Living Area - 5_18.webp';
import Flat5_20 from './images/flats/area1/LORes33/33 60PL Living Area - 6_19.webp';



//marylebone


import Flat6_1 from './images/flats/area2/1 Bedroom Flat/1.webp';
import Flat6_2 from './images/flats/area2/1 Bedroom Flat/2.webp';
import Flat6_3 from './images/flats/area2/1 Bedroom Flat/3.webp';
import Flat6_4 from './images/flats/area2/1 Bedroom Flat/4.webp';
import Flat6_5 from './images/flats/area2/1 Bedroom Flat/5.webp';
import Flat6_6 from './images/flats/area2/1 Bedroom Flat/6.webp';
import Flat6_7 from './images/flats/area2/1 Bedroom Flat/7.webp';


// import Flat6_8 from './images/flats/area2/flat1-60/20170706_151850_19.webp';
// import Flat6_9 from './images/flats/area2/flat1-60/20170706_151918_20.webp';
// import Flat6_10 from './images/flats/area2/flat1-60/Kitchen 1_2.webp';
// import Flat6_11 from './images/flats/area2/flat1-60/Living room 1_3.webp';
// import Flat6_12 from './images/flats/area2/flat1-60/Living room 1_4.webp';
// import Flat6_13 from './images/flats/area2/flat1-60/Living room 1_5.webp';
// import Flat6_14 from './images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-1_6.webp';
// import Flat6_15 from './images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-2_7.webp';
// import Flat6_16 from './images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-3_8.webp';
// import Flat6_17 from './images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-4_9.webp';
// import Flat6_18 from './images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-5_10.webp';
// import Flat6_19 from './images/flats/area2/flat1-60/Marylebone Lane 60 flat 1-6_11.webp';
// import Flat6_20 from './images/flats/area2/flat1-60/bathroom 1_21.webp';
// import Flat6_21 from './images/flats/area2/flat1-60/bedroom 1 1_0.webp';
// import Flat6_22 from './images/flats/area2/flat1-60/bedroom 1 1_22.webp';
// import Flat6_23 from './images/flats/area2/flat1-60/bedroom 2 1_1.webp';





import Flat7_1 from './images/flats/area2/3 Bedroom Duplex Flat/1.webp';
import Flat7_2 from './images/flats/area2/3 Bedroom Duplex Flat/2.webp';
import Flat7_3 from './images/flats/area2/3 Bedroom Duplex Flat/3.webp';
import Flat7_4 from './images/flats/area2/3 Bedroom Duplex Flat/4.webp';
import Flat7_5 from './images/flats/area2/3 Bedroom Duplex Flat/5.webp';
import Flat7_6 from './images/flats/area2/3 Bedroom Duplex Flat/6.webp';
import Flat7_7 from './images/flats/area2/3 Bedroom Duplex Flat/7.webp';
import Flat7_8 from './images/flats/area2/3 Bedroom Duplex Flat/8.webp';
import Flat7_9 from './images/flats/area2/3 Bedroom Duplex Flat/9.webp';
import Flat7_10 from './images/flats/area2/3 Bedroom Duplex Flat/10.webp';

// import Flat7_11 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Dining room 7_47.webp';
// import Flat7_12 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Dining room 9_49.webp';
// import Flat7_13 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Dining room Life Style 2_50.webp';
// import Flat7_14 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Ensuite Bathroom 1_1.webp';
// import Flat7_15 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Ensuite Bathroom 2_2.webp';
// import Flat7_16 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Ensuite Bathroom 3_3.webp';
// import Flat7_17 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Ensuite Bathroom 4_4.webp';
// import Flat7_18 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Hallway 2_5.webp';
// import Flat7_19 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Hallway_6.webp';
// import Flat7_20 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 1_7.webp';
// import Flat7_21 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 3_8.webp';
// import Flat7_22 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 4_9.webp';
// import Flat7_23 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 5_10.webp';
// import Flat7_24 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen 6_11.webp';
// import Flat7_25 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen Life Style 1_12.webp';
// import Flat7_26 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Kitchen Life Style3_13.webp';
// import Flat7_27 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 1_14.webp';
// import Flat7_28 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 2_15.webp';
// import Flat7_29 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 3_16.webp';
// import Flat7_30 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 4_17.webp';
// import Flat7_31 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 5_18.webp';
// import Flat7_32 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 6_19.webp';
// import Flat7_33 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 7_20.webp';
// import Flat7_34 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Living room 8_21.webp';
// import Flat7_35 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 1_22.webp';
// import Flat7_36 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 2_23.webp';
// import Flat7_37 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 3_24.webp';
// import Flat7_38 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 4_25.webp';
// import Flat7_39 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 5_26.webp';
// import Flat7_40 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 6_27.webp';
// import Flat7_41 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 7_28.webp';
// import Flat7_42 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Primary Bedroom 8_29.webp';
// import Flat7_43 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 1_30.webp';
// import Flat7_44 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 2_31.webp';
// import Flat7_45 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 3_32.webp';
// import Flat7_46 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 4_33.webp';
// import Flat7_47 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 5_34.webp';
// import Flat7_48 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 6_35.webp';
// import Flat7_49 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 7_36.webp';
// import Flat7_50 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Second Bedroom 8_37.webp';
// import Flat7_51 from './images/flats/area2/flat1-68/Marylebone 1 - 68 Third Bedroom_38.webp';




import Flat8_1 from './images/flats/area2/2 Bedroom Duplex Flat/1.webp';
import Flat8_2 from './images/flats/area2/2 Bedroom Duplex Flat/2.webp';
import Flat8_3 from './images/flats/area2/2 Bedroom Duplex Flat/3.webp';
import Flat8_4 from './images/flats/area2/2 Bedroom Duplex Flat/4.webp';
import Flat8_5 from './images/flats/area2/2 Bedroom Duplex Flat/5.webp';
import Flat8_6 from './images/flats/area2/2 Bedroom Duplex Flat/6.webp';
import Flat8_7 from './images/flats/area2/2 Bedroom Duplex Flat/7.webp';
import Flat8_8 from './images/flats/area2/2 Bedroom Duplex Flat/8.webp';
import Flat8_9 from './images/flats/area2/2 Bedroom Duplex Flat/9.webp';
import Flat8_10 from './images/flats/area2/2 Bedroom Duplex Flat/10.webp';
import Flat8_11 from './images/flats/area2/2 Bedroom Duplex Flat/11.webp';
import Flat8_12 from './images/flats/area2/2 Bedroom Duplex Flat/12.webp';






import Flat9_1 from './images/flats/area2/2 Bedroom Flat 1/1.webp';
import Flat9_2 from './images/flats/area2/2 Bedroom Flat 1/2.webp';
import Flat9_3 from './images/flats/area2/2 Bedroom Flat 1/3.webp';
import Flat9_4 from './images/flats/area2/2 Bedroom Flat 1/4.webp';
import Flat9_5 from './images/flats/area2/2 Bedroom Flat 1/5.webp';
import Flat9_6 from './images/flats/area2/2 Bedroom Flat 1/6.webp';
import Flat9_7 from './images/flats/area2/2 Bedroom Flat 1/7.webp';
import Flat9_8 from './images/flats/area2/2 Bedroom Flat 1/8.webp';


// import Flat9_9 from './images/flats/area2/flat2-60/The Residence-6_6.webp';


import Flat10_1 from './images/flats/area2/2 Bedroom Flat 2/1.webp';
import Flat10_2 from './images/flats/area2/2 Bedroom Flat 2/2.webp';
import Flat10_3 from './images/flats/area2/2 Bedroom Flat 2/3.webp';
import Flat10_4 from './images/flats/area2/2 Bedroom Flat 2/4.webp';
import Flat10_5 from './images/flats/area2/2 Bedroom Flat 2/5.webp';
import Flat10_6 from './images/flats/area2/2 Bedroom Flat 2/6.webp';
import Flat10_7 from './images/flats/area2/2 Bedroom Flat 2/7.webp';
import Flat10_8 from './images/flats/area2/2 Bedroom Flat 2/8.webp';




// import Flat11_1 from './images/flats/area2/flat3-60/Copy of 68 Marylebone Lane 0034_1.webp';
// import Flat11_2 from './images/flats/area2/flat3-60/Marylebone Lane 60 Flat 3-1 (1)_2.webp';
// import Flat11_3 from './images/flats/area2/flat3-60/Marylebone Lane 60 Flat 3-2_3.webp';
// import Flat11_4 from './images/flats/area2/flat3-60/Marylebone Lane 60 Flat 3-3 (1)_4.webp';
// import Flat11_5 from './images/flats/area2/flat3-60/Marylebone Lane 60 Flat 3-4_0.webp';


// import Flat12_1 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 1_24.webp';
// import Flat12_2 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 2_25.webp';
// import Flat12_3 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 3_26.webp';
// import Flat12_4 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 4_27.webp';
// import Flat12_5 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Ensuite 5_28.webp';
// import Flat12_6 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 1_29.webp';
// import Flat12_7 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 2_30.webp';
// import Flat12_8 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 3_31.webp';
// import Flat12_9 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 4_32.webp';
// import Flat12_10 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Kitchen 5_33.webp';
// import Flat12_11 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 10_6.webp';
// import Flat12_12 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 11_7.webp';
// import Flat12_13 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 12_8.webp';
// import Flat12_14 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 13_9.webp';
// import Flat12_15 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 14_10.webp';
// import Flat12_16 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 1_34.webp';
// import Flat12_17 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 2_35.webp';
// import Flat12_18 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 3_36.webp';
// import Flat12_19 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 4_0.webp';
// import Flat12_20 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 5_1.webp';
// import Flat12_21 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 6_2.webp';
// import Flat12_22 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 7_3.webp';
// import Flat12_23 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 8_4.webp';
// import Flat12_24 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Living room 9_5.webp';
// import Flat12_25 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 1_11.webp';
// import Flat12_26 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 2_12.webp';
// import Flat12_27 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 3 (2)_13.webp';
// import Flat12_28 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 3_14.webp';
// import Flat12_29 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 4_15.webp';
// import Flat12_30 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 5_16.webp';
// import Flat12_31 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 6_17.webp';
// import Flat12_32 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 7_18.webp';
// import Flat12_33 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 8_19.webp';
// import Flat12_34 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom 9_20.webp';
// import Flat12_35 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom Life Style 2_21.webp';
// import Flat12_36 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom Life Style 3_22.webp';
// import Flat12_37 from './images/flats/area2/flat4-60/Marylebone 4 - 60 Primary Bedroom Life Style_23.webp';





// import Flat13_1 from './images/flats/area2/flat5-60/60_9.webp';
// import Flat13_2 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-11_8.webp';
// import Flat13_3 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-1_10.webp';
// import Flat13_4 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-2_0.webp';
// import Flat13_5 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-3_1.webp';
// import Flat13_6 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-4_2.webp';
// import Flat13_7 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-5_3.webp';
// import Flat13_8 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-6_4.webp';
// import Flat13_9 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-7_5.webp';
// import Flat13_10 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-8_6.webp';
// import Flat13_11 from './images/flats/area2/flat5-60/Marylebone Lane 60 Flat 5-9_7.webp';





// import Flat14_1 from './images/flats/area2/flat5-68/68_0.webp';
// import Flat14_2 from './images/flats/area2/flat5-68/68_1.webp';
// import Flat14_3 from './images/flats/area2/flat5-68/68_2.webp';
// import Flat14_4 from './images/flats/area2/flat5-68/68_3.webp';
// import Flat14_5 from './images/flats/area2/flat5-68/68_4.webp';
// import Flat14_6 from './images/flats/area2/flat5-68/68_5.webp';
// import Flat14_7 from './images/flats/area2/flat5-68/68_6.webp';
// import Flat14_8 from './images/flats/area2/flat5-68/68_7.webp';
// import Flat14_9 from './images/flats/area2/flat5-68/68_8.webp';


// import Flat15_1 from './images/flats/area2/flat6-60/60 Marylebone Ln, Marylebone, London W1U 2NZ, UK_26_1.webp';
// import Flat15_2 from './images/flats/area2/flat6-60/Copy of 60 Marylebone Ln, Marylebone, London W1U 2NZ, UK_8_2.webp';
// import Flat15_3 from './images/flats/area2/flat6-60/Marylebone Lane 60 Flat 6-8_0.webp';




// import Flat16_1 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (1 of 21)_5.webp';
// import Flat16_2 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (13 of 21)_1.webp';
// import Flat16_3 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (15 of 21)_2.webp';
// import Flat16_4 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (17 of 21)_3.webp';
// import Flat16_5 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (19 of 21)_4.webp';
// import Flat16_6 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (5 of 21)_6.webp';
// import Flat16_7 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (6 of 21)_7.webp';
// import Flat16_8 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (8 of 21)_8.webp';
// import Flat16_9 from './images/flats/area2/flat7-68/Marylebone Jan 23 Edits (9 of 21)_0.webp';




// import Flat17_1 from './images/flats/area2/flat8-68/Flat 8 Marylebone Edits (1 of 40)_5.webp';
// import Flat17_2 from './images/flats/area2/flat8-68/Flat 8 Marylebone Edits (14 of 40)_1.webp';
// import Flat17_3 from './images/flats/area2/flat8-68/Flat 8 Marylebone Edits (15 of 40)_2.webp';
// import Flat17_4 from './images/flats/area2/flat8-68/Flat 8 Marylebone Edits (2 of 40)_6.webp';
// import Flat17_5 from './images/flats/area2/flat8-68/Flat 8 Marylebone Edits (24 of 40)_3.webp';
// import Flat17_6 from './images/flats/area2/flat8-68/Flat 8 Marylebone Edits (27 of 40)_4.webp';
// import Flat17_7 from './images/flats/area2/flat8-68/Flat 8 Marylebone Edits (4 of 40)_7.webp';
// import Flat17_8 from './images/flats/area2/flat8-68/Flat 8 Marylebone Edits (5 of 40)_0.webp';




// kensington





import Flat18_1 from './images/flats/area3/1 BEDROOM FLAT/1.webp';
import Flat18_2 from './images/flats/area3/1 BEDROOM FLAT/2.webp';
import Flat18_3 from './images/flats/area3/1 BEDROOM FLAT/3.webp';
import Flat18_4 from './images/flats/area3/1 BEDROOM FLAT/4.webp';
import Flat18_5 from './images/flats/area3/1 BEDROOM FLAT/5.webp';
import Flat18_6 from './images/flats/area3/1 BEDROOM FLAT/6.webp';
import Flat18_7 from './images/flats/area3/1 BEDROOM FLAT/7.webp';
import Flat18_8 from './images/flats/area3/1 BEDROOM FLAT/8.webp';
import Flat18_9 from './images/flats/area3/1 BEDROOM FLAT/9.webp';


// import Flat18_10 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 1_10.webp';
// import Flat18_11 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 3(1)_11.webp';
// import Flat18_12 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 3_12.webp';
// import Flat18_13 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 4(1)_13.webp';
// import Flat18_14 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 4_14.webp';
// import Flat18_15 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 5(1)_15.webp';
// import Flat18_16 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 5_16.webp';
// import Flat18_17 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 6(1)_17.webp';
// import Flat18_18 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 6_18.webp';
// import Flat18_19 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 8(1)_19.webp';
// import Flat18_20 from './images/flats/area3/flat101/RUSSELL GARDENS, 101 - 8_0.webp';





import Flat19_1 from './images/flats/area3/2 Bedroom Flat with Terrace/1.webp';
import Flat19_2 from './images/flats/area3/2 Bedroom Flat with Terrace/2.webp';
import Flat19_3 from './images/flats/area3/2 Bedroom Flat with Terrace/3.webp';
import Flat19_4 from './images/flats/area3/2 Bedroom Flat with Terrace/4.webp';
import Flat19_5 from './images/flats/area3/2 Bedroom Flat with Terrace/5.webp';
import Flat19_6 from './images/flats/area3/2 Bedroom Flat with Terrace/6.webp';
import Flat19_7 from './images/flats/area3/2 Bedroom Flat with Terrace/7.webp';
import Flat19_8 from './images/flats/area3/2 Bedroom Flat with Terrace/8.webp';


// import Flat19_9 from './images/flats/area3/flat102/RUSSELL GARDENS, 102 - 4_10.webp';
// import Flat19_10 from './images/flats/area3/flat102/RUSSELL GARDENS, 102 - 5_11.webp';
// import Flat19_11 from './images/flats/area3/flat102/RUSSELL GARDENS, 102 - 7_0.webp';
// import Flat19_12 from './images/flats/area3/flat102/RUSSELL GARDENS, 102 - 8_1.webp';





// import Flat20_1 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 10_2.webp';
// import Flat20_2 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 11_3.webp';
// import Flat20_3 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 13_4.webp';
// import Flat20_4 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 15_5.webp';
// import Flat20_5 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 17_6.webp';
// import Flat20_6 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 18_7.webp';
// import Flat20_7 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 1_8.webp';
// import Flat20_8 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 2_9.webp';
// import Flat20_9 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 4_10.webp';
// import Flat20_10 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 5_11.webp';
// import Flat20_11 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 6_0.webp';
// import Flat20_12 from './images/flats/area3/flat104/RUSSELL GARDENS, 104 - 8_1.webp';




// import Flat21_1 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 12_4.webp';
// import Flat21_2 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 13_5.webp';
// import Flat21_3 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 14_6.webp';
// import Flat21_4 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 16_7.webp';
// import Flat21_5 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 18_8.webp';
// import Flat21_6 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 1_9.webp';
// import Flat21_7 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 2_10.webp';
// import Flat21_8 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 4_11.webp';
// import Flat21_9 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 5_0.webp';
// import Flat21_10 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 6_1.webp';
// import Flat21_11 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 8_2.webp';
// import Flat21_12 from './images/flats/area3/flat201/RUSSELL GARDENS, 201 - 9_3.webp';



// import Flat22_1 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 11_4.webp';
// import Flat22_2 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 12_5.webp';
// import Flat22_3 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 14_6.webp';
// import Flat22_4 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 16_7.webp';
// import Flat22_5 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 18_8.webp';
// import Flat22_6 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 1_9.webp';
// import Flat22_7 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 3_10.webp';
// import Flat22_8 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 4_11.webp';
// import Flat22_9 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 5_0.webp';
// import Flat22_10 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 6_1.webp';
// import Flat22_11 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 8_2.webp';
// import Flat22_12 from './images/flats/area3/flat202/RUSSELL GARDENS, 202 - 9_3.webp';





// import Flat23_1 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-10_2.webp';
// import Flat23_2 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-12_3.webp';
// import Flat23_3 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-13_4.webp';
// import Flat23_4 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-15_5.webp';
// import Flat23_5 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-17_6.webp';
// import Flat23_6 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-1_7.webp';
// import Flat23_7 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-2_8.webp';
// import Flat23_8 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-4_9.webp';
// import Flat23_9 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-5_10.webp';
// import Flat23_10 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-6_11.webp';
// import Flat23_11 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-8_0.webp';
// import Flat23_12 from './images/flats/area3/flat203/RUSSELL GARDENS, 54, 203-9_1.webp';





// import Flat24_1 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-10_4.webp';
// import Flat24_2 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-12_5.webp';
// import Flat24_3 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-13_6.webp';
// import Flat24_4 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-15_7.webp';
// import Flat24_5 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-17_8.webp';
// import Flat24_6 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-19_9.webp';
// import Flat24_7 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-1_10.webp';
// import Flat24_8 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-2_11.webp';
// import Flat24_9 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-4_0.webp';
// import Flat24_10 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-5_1.webp';
// import Flat24_11 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-6_2.webp';
// import Flat24_12 from './images/flats/area3/flat204/RUSSELL GARDENS, 54, 204-8_3.webp';





// import Flat25_1 from './images/flats/area3/flat301/Kensington 301 - Bathroom 1_25.webp';
// import Flat25_2 from './images/flats/area3/flat301/Kensington 301 - Bathroom 2_26.webp';
// import Flat25_3 from './images/flats/area3/flat301/Kensington 301 - Bathroom 4_27.webp';
// import Flat25_4 from './images/flats/area3/flat301/Kensington 301 - Bathroom 5 (2)_28.webp';
// import Flat25_5 from './images/flats/area3/flat301/Kensington 301 - Bathroom 6_29.webp';
// import Flat25_6 from './images/flats/area3/flat301/Kensington 301 - Bedroom 10_3.webp';
// import Flat25_7 from './images/flats/area3/flat301/Kensington 301 - Bedroom 11_4.webp';
// import Flat25_8 from './images/flats/area3/flat301/Kensington 301 - Bedroom 1_30.webp';
// import Flat25_9 from './images/flats/area3/flat301/Kensington 301 - Bedroom 2_31.webp';
// import Flat25_10 from './images/flats/area3/flat301/Kensington 301 - Bedroom 3_32.webp';
// import Flat25_11 from './images/flats/area3/flat301/Kensington 301 - Bedroom 4_33.webp';
// import Flat25_12 from './images/flats/area3/flat301/Kensington 301 - Bedroom 5_34.webp';
// import Flat25_13 from './images/flats/area3/flat301/Kensington 301 - Bedroom 6_35.webp';
// import Flat25_14 from './images/flats/area3/flat301/Kensington 301 - Bedroom 7_0.webp';
// import Flat25_15 from './images/flats/area3/flat301/Kensington 301 - Bedroom 8_1.webp';
// import Flat25_16 from './images/flats/area3/flat301/Kensington 301 - Bedroom 9_2.webp';
// import Flat25_17 from './images/flats/area3/flat301/Kensington 301 - Kitchen and Dining_5.webp';
// import Flat25_18 from './images/flats/area3/flat301/Kensington 301 - Kitchen_6.webp';
// import Flat25_19 from './images/flats/area3/flat301/Kensington 301 - Living Room 10_14.webp';
// import Flat25_20 from './images/flats/area3/flat301/Kensington 301 - Living Room 11_15.webp';
// import Flat25_21 from './images/flats/area3/flat301/Kensington 301 - Living Room 3_7.webp';
// import Flat25_22 from './images/flats/area3/flat301/Kensington 301 - Living Room 4_8.webp';
// import Flat25_23 from './images/flats/area3/flat301/Kensington 301 - Living Room 5_9.webp';
// import Flat25_24 from './images/flats/area3/flat301/Kensington 301 - Living Room 6_10.webp';
// import Flat25_25 from './images/flats/area3/flat301/Kensington 301 - Living Room 7_11.webp';
// import Flat25_26 from './images/flats/area3/flat301/Kensington 301 - Living Room 8 (2)_12.webp';
// import Flat25_27 from './images/flats/area3/flat301/Kensington 301 - Living Room 9_13.webp';
// import Flat25_28 from './images/flats/area3/flat301/Kensington 301 - Living Room_16.webp';
// import Flat25_29 from './images/flats/area3/flat301/Kensington 301- Bedroom 6_17.webp';
// import Flat25_30 from './images/flats/area3/flat301/Kensington 301- Living Room 12_18.webp';
// import Flat25_31 from './images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-13_23.webp';
// import Flat25_32 from './images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-18_24.webp';
// import Flat25_33 from './images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-2_19.webp';
// import Flat25_34 from './images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-5_20.webp';
// import Flat25_35 from './images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-6_21.webp';
// import Flat25_36 from './images/flats/area3/flat301/RUSSELL GARDENS, 54, 301-9_22.webp';







// import Flat26_1 from './images/flats/area3/flat302/Kensington 302 - Bathroom _1.webp';
// import Flat26_2 from './images/flats/area3/flat302/Kensington 302 - Bedroom_2.webp';
// import Flat26_3 from './images/flats/area3/flat302/Kensington 302 - Kitchen and Living 4_0.webp';




// import Flat27_1 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-1(1)_11.webp';
// import Flat27_2 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-10_3.webp';
// import Flat27_3 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-12_4.webp';
// import Flat27_4 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-15_5.webp';
// import Flat27_5 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-16_6.webp';
// import Flat27_6 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-17(1)_7.webp';
// import Flat27_7 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-17_8.webp';
// import Flat27_8 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-18(1)_9.webp';
// import Flat27_9 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-18_10.webp';
// import Flat27_10 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-1_12.webp';
// import Flat27_11 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-2_13.webp';
// import Flat27_12 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-4(1)_14.webp';
// import Flat27_13 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-4_15.webp';
// import Flat27_14 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-5_16.webp';
// import Flat27_15 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-6(1)_0.webp';
// import Flat27_16 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-6_1.webp';
// import Flat27_17 from './images/flats/area3/flat304/RUSSELL GARDENS, 54, 304-8_2.webp';




import Lines from './images/contact.png';

function AllApartments(){
    return(
        <>
            <section className='bestdeals'>
                <img src={Lines} alt="lines" />
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='bestdeals-header'>
                                <h2>
                                    Exclusive Offers
                                </h2>

                                <h1> 
                                    All London Apartments
                                </h1>
                            </div>
                        </div>



                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=1">
                                        <img src={Flat1_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>



                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=2">
                                        <img src={Flat2_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=3">
                                        <img src={Flat3_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>



{/* 

                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=4">
                                        <img src={Flat4_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=5">
                                        <img src={Flat5_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


 */}

                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=6">
                                        <img src={Flat6_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 1</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                </div>

                                    <h1>
                                    1 Bedroom Flat
                                    </h1>
                                    <h2>IN MARYLEBONE, W1U</h2>

                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=7">
                                        <img src={Flat7_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 3</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 2</h4>

                                </div>



                                    <h1>
                                    3 Bedroom Duplex Flat
                                    </h1>
                                    <h2>IN MARYLEBONE, W1U </h2>

                                 
                                </div>
                            </div>
                        </div>





                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=8">
                                        <img src={Flat8_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 2</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                </div>



                                    <h1>
                                    2 Bedroom Duplex Flat
                                    </h1>
                                    <h2>IN MARYLEBONE, W1U</h2>

                                 
                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=9">
                                        <img src={Flat9_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 2</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                </div>

                                    <h1>
                                    2 Bedroom Flat
                                    </h1>
                                    <h2>IN MARYLEBONE, W1U</h2>

                                 
                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=10">
                                        <img src={Flat10_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                <div>

                                <h4> <span> <i class="las la-bed"></i></span> 2</h4>
                                <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                </div>

                                    <h1>
                                    2 Bedroom Flat
                                    </h1>
                                    <h2>IN MARYLEBONE, W1U</h2>

                                
                                </div>
                            </div>
                        </div>

{/* 
                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=11">
                                        <img src={Flat11_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=12">
                                        <img src={Flat12_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=13">
                                        <img src={Flat13_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <h1>
                                    3 Bedroom Duplex Flat
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=14">
                                        <img src={Flat14_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=15">
                                        <img src={Flat15_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>
   <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=16">
                                        <img src={Flat16_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=17">
                                        <img src={Flat17_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>
 */}

 
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=18">
                                        <img src={Flat18_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>
                                    <div>

                                        <h4> <span> <i class="las la-bed"></i></span> 1</h4>
                                        <h4> <span> <i class="las la-bath"></i></span> 1</h4>

                                    </div>
                                    <h1>
                                    1 Bedroom Flat
                                    </h1>
                                    <h2>IN KENSINGTON, W14</h2>

                                
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=19">
                                        <img src={Flat19_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

                                <div>

                                    <h4> <span> <i class="las la-bed"></i></span> 2</h4>
                                    <h4> <span> <i class="las la-bath"></i></span> 2</h4>

                                </div>

                                    <h1>
                                        2 Bedroom Flat with Terrace
                                    </h1>
                                    <h2>IN KENSINGTON, W1T</h2>

                                
                                </div>
                            </div>
                        </div>
{/*   
                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=20">
                                        <img src={Flat20_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=21">
                                        <img src={Flat21_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=22">
                                        <img src={Flat22_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=23">
                                        <img src={Flat23_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=24">
                                        <img src={Flat24_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>



                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=25">
                                        <img src={Flat25_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=26">
                                        <img src={Flat26_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>




                        
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='apartment'>
                                <div className='image'>
                                    <RouterLink to="/apartment?id=27">
                                        <img src={Flat27_1} alt="apartment image"/>
                                    </RouterLink>
                                </div>

                                <div className='text'>

  <div>

                                    <h4> <span> <i class="las la-bed"></i></span> </h4>
                                    <h4> <span> <i class="las la-bath"></i></span> </h4>

                                </div>
                                    <h1>
                                        APARTMENT
                                    </h1>
                                    <h2>Showcase</h2>

                                 
                                </div>
                            </div>
                        </div>


 */}


                    </div>
                </div>
            </section>
        </>
    )
}

export default AllApartments;